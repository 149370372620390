import axios from "axios";
import { debounce_promise } from "@/js/utils";

export const get_taxes = async () => {
  const url = `/inventory/get_taxes/`;
  try {
    const response = await axios.get(url);
    return { data: response.data, error: null };
  } catch (error) {
    console.error("Error while fetching taxes !:", error);
    return { data: null, error: "Failed to fetch taxes data" };
  }
};

export const search_products = debounce_promise(async (val) => {
  const url = `/inventory/get_products/?search=${val}`;
  try {
    const response = await axios.get(url);
    return { data: response.data, errors: null };
  } catch (errors) {
    console.error("Error while searching products !:", errors);
    return { data: null, errors: "Error while searching products !" };
  }
}, 300); // Delay of 300ms
