<template>
  <vue-instant
    :dir="get_page_direction"
    :suggestOnAllWords="true"
    :suggestion-attribute="suggestionAttribute"
    @input="changed"
    v-model="value"
    @click-input="clickInput"
    @click-button="clickButton"
    @selected="selected"
    @enter="enter"
    @key-up="keyUp"
    @key-down="keyDown"
    @key-right="keyRight"
    @clear="clear"
    @escape="escape"
    :show-autocomplete="true"
    :autofocus="false"
    :suggestions="suggestions"
    :disabled="false"
    name="customName"
    :placeholder="placeholder"
    type="custom"
  ></vue-instant>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { search_products } from "@/js/services/api_services";
import { display_error_messages } from "@/js/utils";

export default {
  name: "vue_instant_search",
  props: {
    placeholder: {
      type: String,
      default: "Search Product by name or barcode ..",
    },
    products: {
      type: Array,
    },
    on_select: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters("auth", ["is_logged_in", "user_info", "get_page_direction"]),
  },
  data() {
    return {
      value: "",
      suggestionAttribute: "name",
      suggestions: [],
      selectedEvent: "",
    };
  },
  methods: {
    ...mapActions("temporary", ["set_temp_array", "set_temp_object"]),
    bind_product() {
      // auto select if the result has only one value
      if (this.value.length > 0 && this.suggestions.length === 1) {
        this.value = this.suggestions[0][this.suggestionAttribute];
        this.set_temp_object(this.suggestions[0]);
        // on_select is a function parsed as a component from the parent which is in add_item in this case
        if (this.on_select) {
          this.on_select();
        }
      }
    },
    clickInput() {
      this.selectedEvent = "click input";
    },
    clickButton() {
      this.selectedEvent = "click button";
      this.bind_product();
    },
    selected() {
      this.selectedEvent = "selection changed";
      this.bind_product();
    },
    enter() {
      this.bind_product();
    },
    keyUp() {
      this.selectedEvent = "keyup pressed";
    },
    keyDown() {
      this.selectedEvent = "keyDown pressed";
    },
    keyRight() {
      this.selectedEvent = "keyRight pressed";
    },
    clear() {
      this.selectedEvent = "clear input";
    },
    escape() {
      this.selectedEvent = "escape";
    },
    changed() {
      if (!this.value.length) return;

      this.suggestions = [];
      // check if input value is a valid barcode
      const barcode =
        !isNaN(this.value) && this.value.length >= 5 ? this.value : false;

      if (barcode) {
        this.suggestionAttribute = "barcode";
      } else {
        this.suggestionAttribute = "name";
      }

      search_products(this.value).then(({ data, errors }) => {
        if (data && data.length > 0) {
          this.suggestions = data;
        }
        if (errors) {
          display_error_messages(errors);
        }
      });
    },
  },
};
</script>

<style>
/**********************************************

Vue Instant Custom style generator : https://shipow.github.io/searchbox/#tabprefix
Note there is a problem with .sbx-custom__input-placeholder in the website so you need to add it manually
and also the dropdown suggestions .vue-instant__suggestions li.highlighted__custom


************************/

.sbx-custom {
  display: inline-block;
  position: relative;
  width: 50vw;
  height: 41px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
}

.sbx-custom__wrapper {
  width: 100%;
  height: 100%;
}

.sbx-custom__input {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #cccccc;
  background: #ffffff;
  padding: 0 77px 0 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-custom__input-placeholder {
  display: inline-block;
  -webkit-transition: box-shadow 0.4s ease, background 0.4s ease;
  transition: box-shadow 0.4s ease, background 0.4s ease;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #cccccc;
  background: #ffffff;
  padding: 0;
  padding-right: 77px;
  padding-left: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sbx-custom__input::-webkit-search-decoration,
.sbx-custom__input::-webkit-search-cancel-button,
.sbx-custom__input::-webkit-search-results-button,
.sbx-custom__input::-webkit-search-results-decoration {
  display: none;
}

.sbx-custom__input:hover {
  box-shadow: inset 0 0 0 1px #b3b3b3;
}

.sbx-custom__input:focus,
.sbx-custom__input:active {
  outline: 0;
  box-shadow: inset 0 0 0 1px #3e82f7;
  background: #ffffff;
}

.sbx-custom__input::-webkit-input-placeholder {
  color: #aaaaaa;
}

.sbx-custom__input::-moz-placeholder {
  color: #aaaaaa;
}

.sbx-custom__input:-ms-input-placeholder {
  color: #aaaaaa;
}

.sbx-custom__input::placeholder {
  color: #aaaaaa;
}

.sbx-custom__submit {
  position: absolute;
  top: 0;
  right: 0;
  left: inherit;
  margin: 0;
  border: 0;
  border-radius: 0 3px 3px 0;
  background-color: #3e82f7;
  padding: 0;
  width: 49px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sbx-custom__submit::before {
  display: inline-block;
  margin-right: -4px;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.sbx-custom__submit:hover,
.sbx-custom__submit:active {
  cursor: pointer;
}

.sbx-custom__submit:focus {
  outline: 0;
}

.sbx-custom__submit svg {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  fill: #ffffff;
}

.sbx-custom__reset {
  display: none;
  position: absolute;
  top: 10px;
  right: 56px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: rgba(0, 0, 0, 0.5);
}

.sbx-custom__reset:focus {
  outline: 0;
}

.sbx-custom__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.sbx-custom__input:valid ~ .sbx-custom__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
  animation-name: sbx-reset-in;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}

.vue-instant__suggestions li.highlighted__custom {
  background-color: #eee;
  color: #000;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
</style>
