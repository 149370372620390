export default {
  // forbidden
  forbidden: "Forbidden",
  forbidden_desc: "Sorry, you don't have permission to access this resource.",

  save: "Save",
  search: "search",
  details: "Details",
  name: "Name",
  type: "Type",
  promo: "Promo",
  branch: "Branch",

  all: "All",

  // crud
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  control: "Control",
  active: "Active",
  new: "New",
  add_new: "Add New",
  remove: "Remove",
  chose: "Chose",

  // date
  from: "From",
  to: "To",
  date: "date",
  date_time: "Date / Time",

  // users
  user: "User",
  users: "Users",
  last_modified_by: "Last Modified By",
  last_modified: "Last Modified",
  last_visit: "Last Visit",
  cashier: "Cashier",
  client: "Client",
  customers: "Customers",
  customer: "Customer",
  supplier: "Supplier",
  suppliers: "Suppliers",
  customer_or_supplier: "Customer/Supplier",
  created_by: "Created By",

  // user info
  first_name: "First Name",
  last_name: "Last Name",
  last_login: "Last Login",
  phone: "Phone",
  telephone: "Telephone",
  email: "Email",
  address: "Address",
  creation_date: "Date Of Registration",
  vat_reg_num: "Vat Registration Number",
  password: "Password",

  // places
  city: "City",
  governorate: "Governorate",
  place: "Place",

  // counting
  amount: "Amount",
  subtotal: "Subtotal",
  tax: "Tax",
  taxes: "Taxes",
  tax_inc: "Tax Included",
  tax_total: "Tax Total",
  total: "Total",
  price: "Price",
  price_before_tax: "Price Before Tax",
  quantity: "Quantity",
  discount: "Discount",
  s_discount: "Discount",

  // Split payments
  change: "Change",
  balance: "Balance",
  received: "Received",
  remaining_to_rec: "Remaining to be received",
  ok: "Ok",

  discount_before_tax: "Apply Discount Before tax",
  discount_after_tax: "Apply Discount After tax",
  fixed_discount: "Fixed Discount",
  percentage_discount: "Percentage Discount",

  total_before_discount: "Total Before Discount",
  total_before_tax: "Total Before Tax",
  total_after_tax: "Total After Tax",

  total_cost_before_tax: "Total Cost Before Tax",
  total_cost_after_tax: "Total Cost After Tax",

  total_cost_before_discount: "Total Cost Before Discount",
  total_cost_after_discount: "Total Cost After Discount",

  invoice: "Invoice",
  invoice_no: "Invoice No",
  value: "Value",
  items_count: "Items Count",
  sale_price_before_tax: "Sale Price before Tax",
  sale_price: "Sale Price",

  cost: "Cost",
  cost_before_tax: "Cost Before Tax",
  cost_after_tax: "Cost After Tax",

  unit_price: "Unit Price",
  count: "Count",
  ref_number: "Reference Number",
  paid: "Paid",
  unpaid: "Unpaid",
  status: "Status", // todo: replace inventory status

  fixed: "Fixed",
  percentage: "Percentage",

  // names
  product_name: "Product Name",
  products: "Products",
  group: "Group",
  code: "Code",
  barcode: "Barcode",
  item_boxes: "ItemBoxes",

  // placeholders
  search_client_or_supplier: "search for client or supplier ...",
  search_by_user: "search by username",
  search_by_product_name_or_barcode: "search by product name or barcode",

  // dropdowns
  chose_a_group: "----- Chose A Group-----",
  chose_a_supplier: "----- Chose A Supplier-----",
  default: "--- Default ---",
  chose_a_tax: "--- Chose A Tax ---",

  // popups
  delete_confirm: " Are You Sure you want to Delete",

  // utils
  next: "Next",
  previous: "Previous",
  print: "Print",
  print_invoice: "Print Invoice",
  enable: "Enable",
  enabled: "Enabled",

  // days
  day_sat: "Sat",
  day_sun: "Sun",
  day_mon: "Mon",
  day_tue: "Tue",
  day_wed: "Wed",
  day_thu: "Thu",
  day_fri: "Fri",
  days: "Days",
  everyday: "EveryDay",
};
