export default [
  {
    path: "/management/addons",
    name: "addons",
    component: () => import("@/views/management/products/addons/AddonsMain"),
    meta: {
      title: () => {
        return "OnePos | Addons";
      },
    },
  },
  {
    path: "/management/addons/:addon_id",
    name: "addon_details",
    component: () => import("@/views/management/products/addons/AddonDetails"),
    meta: {
      title: (route) => {
        return `OnePos | Addon Details ${route.params.addon_id}`; // manually edited in the view to get name instead of addon_id when get addon name from the api
      },
    },
  },
];
