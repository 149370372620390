<template>
  <div class="pos-container full-height">
    <!-- invoice_list_modal  -->
    <invoice_list_modal />
    <!-- invoice_list_modal  -->

    <!-- quantity modal -->
    <quantity_modal ref="quantity_modal" :selected_item="get_active_item" />
    <!-- quantity modal END -->

    <!-- Addons Modal -->
    <add_on_modal ref="addon_modal" />
    <!-- Addons Modal -->

    <!-- itembox Modal -->
    <itembox_modal :clicked_itembox_product="clicked_itembox_product" />
    <button
      ref="show_itembox_modal_btn"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#itembox-modal"
      class="d-none"
    ></button>
    <!-- itembox Modal -->

    <!-- Discount Modal -->
    <discount_modal />
    <!-- Discount Modal -->

    <!-- Clients Modal -->
    <clients_modal />
    <!-- Clients Modal -->

    <!-- Change Price Modal -->
    <change_price_modal />
    <!-- Change Price Modal -->

    <nav class="pos-nav d-flex flex-wrap gap-2 align-items-baseline">
      <div
        class="products-search d-none d-md-flex col-auto justify-content-between"
      >
        <search_products />
      </div>
      <div class="nav-buttons col d-flex flex-wrap gap-1">
        <button
          @click="open_cart_modal"
          class="nav-button flex-fill position-relative px-5 d-block d-md-none"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.75 13.9699C4.61 15.5999 5.9 16.9999 7.54 16.9999H18.19C19.63 16.9999 20.89 15.8199 21 14.3899L21.54 6.88989C21.66 5.22989 20.4 3.87988 18.73 3.87988H5.82001"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L5.08 10.05"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 8H21"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span
            class="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger"
            :style="
              get_current_order.items.length > 0 ? '' : { display: 'none' }
            "
            >{{ get_current_order.items.length }}
          </span>
        </button>
        <button
          id="discount-btn"
          class="nav-button flex-fill"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#order-discount-modal"
        >
          <img :src="$get_icon('DiscountIcon')" alt="discount icon" />
          {{ $t("common.discount") }}
        </button>

        <button
          @click="open_or_reset_client"
          id="clients-btn"
          class="nav-button flex-fill"
          type="button"
        >
          <img :src="$get_icon('CustomerIcon')" alt="customer icon" />

          <span v-if="get_order_client">{{ get_order_client.name }}</span>
          <span class="mr-5 text-start" v-else>
            {{ $t("common.customers") }}
          </span>
        </button>

        <button
          @click="save_order"
          type="button"
          class="nav-button flex-fill position-relative"
        >
          <span
            ref="save_sale_btn"
            data-bs-toggle="modal"
            data-bs-target="#save-sale-modal"
          ></span>
          {{ $t("sell_screen.save_sale") }}

          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            :style="saved_orders.length > 0 ? '' : { display: 'none' }"
            >{{ saved_orders.length }}
          </span>
        </button>
        <button
          class="nav-button flex-fill"
          id="addons-btn"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addons-modal"
          @click="
            () => {
              this.$refs.addon_modal.generate_selected_addons_array();
            }
          "
          :disabled="Object.keys(get_active_item).length === 0"
        >
          <img :src="$get_icon('AddonsIcon')" alt="addons icon" />

          {{ $t("sell_screen.addons") }}
        </button>
        <router-link
          custom
          v-slot="{ href, navigate }"
          :to="{ name: 'invoices' }"
        >
          <button
            :href="href"
            @click="navigate"
            id="invoices-btn"
            type="button"
            class="nav-button flex-fill"
          >
            <img :src="$get_icon('InvoicesIcon')" alt="invoices icon" />
            <span> {{ $t("sell_screen.invoices") }} </span>
          </button>
        </router-link>

        <button @click="reset_order" class="nav-button flex-fill">
          <img :src="$get_icon('VoidOrderIcon')" alt="void order icon" />
          {{ $t("sell_screen.void_order") }}
        </button>
      </div>
    </nav>
    <div class="pos-hero d-flex flex-wrap gap-1 mt-3">
      <div class="products col-12 col-md-9 h-100">
        <div
          class="products-wrapper d-flex flex-wrap gap-1 justify-content-between"
        >
          <!-- change the current products window to show the search result  -->
          <template v-if="sf">
            <div
              @click="add_item(product)"
              v-for="product in searched_products"
              :key="product.id"
              class="product-card"
            >
              <product_card :product_info="product" />
            </div>
          </template>
          <template v-else>
            <!-- check if the user is inside a group [create back button ] -->
            <div
              v-if="current_group.id"
              @click="get_group_products(current_group.parent)"
              class="back-card group-card"
            >
              <div class="wrapper">
                <div class="name">{{ current_group.name }}</div>
                <div class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1299 19.0601H7.12988C6.71988 19.0601 6.37988 18.7201 6.37988 18.3101C6.37988 17.9001 6.71988 17.5601 7.12988 17.5601H15.1299C17.4699 17.5601 19.3799 15.6501 19.3799 13.3101C19.3799 10.9701 17.4699 9.06006 15.1299 9.06006H4.12988C3.71988 9.06006 3.37988 8.72006 3.37988 8.31006C3.37988 7.90006 3.71988 7.56006 4.12988 7.56006H15.1299C18.2999 7.56006 20.8799 10.1401 20.8799 13.3101C20.8799 16.4801 18.2999 19.0601 15.1299 19.0601Z"
                      fill="#292D32"
                    />
                    <path
                      d="M6.43006 11.5599C6.24006 11.5599 6.05006 11.4899 5.90006 11.3399L3.34006 8.77988C3.05006 8.48988 3.05006 8.00988 3.34006 7.71988L5.90006 5.15988C6.19006 4.86988 6.67006 4.86988 6.96006 5.15988C7.25006 5.44988 7.25006 5.92988 6.96006 6.21988L4.93006 8.24988L6.96006 10.2799C7.25006 10.5699 7.25006 11.0499 6.96006 11.3399C6.82006 11.4899 6.62006 11.5599 6.43006 11.5599Z"
                      fill="#292D32"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              v-for="group in groups"
              :key="'group' + group.id"
              @click="get_group_products(group.id)"
              class="group-card"
            >
              <group_card :group_info="group" />
            </div>

            <div
              @click="add_item(product)"
              v-for="product in products"
              :key="product.id"
              class="product-card"
            >
              <product_card :product_info="product" />
            </div>
          </template>
        </div>
        <div class="pagination d-flex flex-column justify-content-end">
          <div class="col-12 d-flex align-items-baseline gap-3">
            <button
              @click="previous_page"
              :disabled="!pagination.previous"
              class="col p-3"
            >
              {{ $t("common.previous") }}
            </button>

            <span class="page-indicator col text-center">
              <span class="current">Page {{ page }} </span>of
              {{ pagination.total_pages }}
            </span>

            <button
              @click="next_page"
              :disabled="!pagination.next"
              class="col p-3"
            >
              {{ $t("common.next") }}
            </button>
          </div>
        </div>
      </div>
      <invoice_list />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import search_products from "@/components/sell_screen/search_products.vue";
import product_card from "@/components/sell_screen/product_card.vue";
import group_card from "@/components/sell_screen/group_card";
import { OrderItem } from "@/js/sell_screen/order/order_item";

import { add_item, show_itembox_modal } from "@/js/sell_screen/invoice_list";
import { copy_order_item } from "@/js/sell_screen/helpers";

import { Modal } from "bootstrap";

export default {
  name: "SellScreen",
  computed: {
    ...mapGetters({
      is_logged_in: "auth/is_logged_in",
      user_info: "auth/user_info",

      get_current_order: "sell_screen/get_current_order",
      get_order_client: "sell_screen/get_order_client",
      get_active_index: "sell_screen/get_active_index",
      get_active_item: "sell_screen/get_active_item",

      // save_sale
      saved_orders: "saved_orders/get_saved_orders",
      get_current_saved_order: "saved_orders/get_current_saved_order",

      settings: "general_settings/get_settings",
      get_navbar_content: "navbar/get_navbar_content",
    }),
    is_small_screen() {
      return window.innerWidth <= 768;
    },
  },
  components: {
    // DiscountIcon,
    // CustomerIcon,
    // VoidOrderIcon,
    search_products,
    product_card,
    group_card,
    invoice_list: () => import("@/components/sell_screen/invoice_list.vue"),
    invoice_list_modal: () =>
      import("@/components/sell_screen/invoice_list_modal.vue"),
    quantity_modal: () => import("@/components/sell_screen/quantity_modal.vue"),
    add_on_modal: () => import("@/components/sell_screen/add_on_modal.vue"),
    itembox_modal: () => import("@/components/sell_screen/itembox_modal.vue"),
    discount_modal: () => import("@/components/sell_screen/discount_modal.vue"),
    clients_modal: () => import("@/components/sell_screen/clients_modal.vue"),
    change_price_modal: () =>
      import("@/components/sell_screen/change_price_modal.vue"),
  },
  data() {
    return {
      // will be filled from add_item function
      clicked_itembox_product: {},
      //groups
      groups: [],
      current_group: {},
      // products
      products: [],
      cached_products: [], // products that have been searched
      searched_products: [], // to render the searched products
      sf: false, // searching flag
      //order
      s_order: [],

      // reorder
      reorder_products: [],

      // pagination
      page: 1,
      pagination: {
        count: 0,
        next: false,
        previous: false,
        total_pages: 0,
      },
    };
  },
  watch: {},
  methods: {
    ...mapActions("navbar", ["set_navbar_content"]),
    ...mapActions("general_settings", ["set_settings"]),
    ...mapActions("sell_screen", [
      "set_current_order",
      "delete_current_order",
      "set_order_client",
      "reset_order_client",
    ]),
    open_or_reset_client() {
      if (this.get_order_client) {
        this.reset_order_client();
        return;
      }
      const client_modal = new Modal(document.getElementById("client-modal"));
      client_modal.show();
    },
    // invoice_list
    add_item(order_item) {
      if (order_item.has_itemboxes()) {
        show_itembox_modal(this, order_item);
      } else {
        const order_item_copy = copy_order_item(order_item);
        return add_item(this, order_item_copy);
      }
    },
    // products
    get_group_products(group_pk) {
      this.current_group = {};
      const url = group_pk
        ? `/sell_screen/get_products/?group_pk=${group_pk}&page=1`
        : `/sell_screen/get_products/?page=${this.page}`;

      this.$isLoading(true);

      this.$http
        .get(url)
        .then((response) => {
          this.pagination = response.data.pagination;

          if (response.data.results["group"]) {
            this.current_group = response.data.results["group"];
            this.groups = response.data.results["group"].children;
            // filter products by tax property because data is mixed between groups, products
            // response.data.results.data -> to get only the products of that group
            this.products = response.data.results.data.filter(
              (product) => product.tax
            );
          } else {
            this.groups = response.data.results.filter((g) => g.path);
            // filter products by tax property because data is mixed between groups, products
            this.products = response.data.results.filter(
              (product) => product.tax
            );
          }
          // convert products to OrderItem class
          this.products = this.products.map(
            (product) => new OrderItem(product, [], 1, 0, 0, 0)
          );
        })
        .catch((e) => {
          console.log(`Error while fetching groups ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },

    // reorder point
    // check_reorder_qty(product) {
    //   // check if ordered qty in current_order hits product reorder
    //
    //   if (
    //     parseFloat(product.reorder_point) >=
    //     product.quantity - product.ordered_quantity
    //   ) {
    //     if (
    //       this.reorder_products.findIndex((name) => name === product.name) <= -1
    //     ) {
    //       // prevent duplication if item already exists
    //       this.reorder_products.push(product.name);
    //     }
    //   }
    // },
    // push_reorder() {
    //   for (let product of this.reorder_products) {
    //     this.$store.dispatch("utils/push_alert", {
    //       message: `You Need to Order <h5 class="d-inline-block"> ${product} </h5>`,
    //       type: "warning",
    //     });
    //   }
    // },
    // sell_screen settings
    get_order_settings() {
      this.$http
        .get(`/settings/get_order_settings/`)
        .then((response) => {
          this.set_settings({ ...this.settings, ...response.data });
        })
        .catch((e) => {
          console.log(`Error while fetching settings ! : ${e}`);
        });
    },
    get_scale_settings() {
      this.$http
        .get(`/settings/get_scale_settings/`)
        .then((response) => {
          this.set_settings({ ...this.settings, scale: { ...response.data } });
        })
        .catch((e) => {
          console.log(`Error while fetching scale settings ! : ${e}`);
        });
    },
    // save Sale
    save_order() {
      if (this.get_current_order.items.length > 0) {
        this.$store.dispatch("saved_orders/save_order", this.get_current_order);
        this.reset_order();
      } else {
        this.$router.push({ name: "saved_orders" });
      }
    },
    // order
    reset_order() {
      this.$store.dispatch("saved_orders/delete_current_order");
      this.$store.dispatch("sell_screen/delete_current_order");

      this.reorder_products = [];

      this.s_order = [];
    },
    open_cart_modal() {
      const client_modal = new Modal(
        document.getElementById("invoice-list-modal")
      );
      client_modal.show();
    },
    // pagination
    next_page() {
      if (this.pagination.next) {
        this.page++;
        this.get_group_products();
      }
    },
    previous_page() {
      if (this.pagination.previous) {
        this.page--;
        this.get_group_products();
      }
    },
    handle_resize() {
      // console.log("this.is_small_screen: ", this.is_small_screen);

      if (this.is_small_screen) {
        this.set_navbar_content({
          type: "component",
          content: search_products,
        });
      } else {
        this.set_navbar_content({ type: "html", content: "" });
      }
    },
  },
  created() {
    this.get_group_products(0);
    this.get_order_settings();
    this.get_scale_settings();

    if (this.get_current_saved_order) {
      this.set_current_order(this.get_current_saved_order);
    }
  },
  mounted() {
    window.addEventListener("resize", this.handle_resize);
    this.handle_resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handle_resize);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/home/sell_screen.scss";
@import "@/scss/home/sell_screen_responsive.scss";
</style>
