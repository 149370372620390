<template>
  <div class="stock full-height pb-5 pb-md-0">
    <p class="sec-name d-none d-md-block">
      {{ $t("inventory.quantities_in_the_stock") }}
    </p>
    <form class="filters">
      <label>
        <input
          @click="get_by_filter"
          value="all"
          type="radio"
          name="filters"
          checked
        />

        {{ $t("common.all") }}
      </label>
      <label>
        <input
          @click="get_by_filter"
          value="n_qty"
          type="radio"
          name="filters"
        />

        {{ $t("inventory.negative_quantity") }}
      </label>
      <label
        ><input
          @click="get_by_filter"
          value="z_qty"
          type="radio"
          name="filters"
        />
        {{ $t("inventory.zero_quantity") }}
      </label>

      <label
        ><input
          @click="get_by_filter"
          value="p_qty"
          type="radio"
          name="filters"
        />

        {{ $t("inventory.non_zero_quantity") }}
      </label>
    </form>

    <div class="search">
      <label>
        <input
          @input="search"
          class="pos-input p-2"
          type="text"
          :placeholder="$t('common.search_by_product_name_or_barcode')"
        />
      </label>
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="$t('inventory.negative_quantity')"
        class="box"
      >
        {{ items_count.n_qty }}
      </div>
      <!-- /.box -->
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        :title="$t('inventory.items_with_zero_quantity')"
        class="box"
      >
        {{ items_count.z_qty }}
      </div>
      <!-- /.box -->
      <div
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        :title="$t('inventory.items_with_quantity_greater_than_zero')"
        class="box"
      >
        {{ items_count.p_qty }}
      </div>
      <!-- /.box -->
    </div>
    <!-- /.search -->
    <div class="table-wrapper mt-2 w-100">
      <table class="primary-table">
        <thead>
          <tr>
            <th>{{ $t("common.code") }}</th>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th>{{ $t("common.unit_price") }}</th>
            <th>{{ $t("common.cost") }}</th>
            <th>{{ $t("common.value") }}</th>
            <th>{{ $t("common.total_after_tax") }}</th>
            <th>{{ $t("inventory.product_type") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.price_with_tax }}</td>
            <td>{{ product.last_cost }}</td>
            <td>{{ (product.last_cost * product.quantity).toFixed(2) }}</td>
            <td>
              {{ (product.price_with_tax * product.quantity).toFixed(2) }}
            </td>
            <td
              :class="
                product.item_type === 'product'
                  ? 'text-primary'
                  : 'text-warning'
              "
            >
              {{ $t(`inventory.${product.item_type}`) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pag mt-3">
      <button
        @click="previous_page"
        :disabled="!pagination.previous"
        class="pos-button p-2"
      >
        {{ $t("common.previous") }}
      </button>
      <span class="p-2"
        >{{ pagination.current_page }} of {{ pagination.total_pages }}</span
      >
      <button
        @click="next_page"
        :disabled="!pagination.next"
        class="pos-button"
      >
        {{ $t("common.next") }}
      </button>
    </div>
    <!-- /.pag -->
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import { mapActions } from "vuex";

export default {
  name: "StockQuantities",
  data() {
    return {
      items_count: { p_qty: 0, z_qty: 0, n_qty: 0 },
      products: [],
      pagination: {
        current_page: 1,
        next: false,
        previous: false,
        count: 0,
        total_pages: 0,
      },
      name_search: "",
      filter_type: "all",
    };
  },
  methods: {
    ...mapActions("utils", ["change_is_forbidden", "append_forbidden_error"]),
    get_url_filters() {
      /* getting url search params & filters */
      let params = `?page=${this.pagination.current_page}&filter_type=${this.filter_type}`;
      if (this.name_search) {
        params += "&name=" + this.name_search;
      }
      return params;
    },
    get_products() {
      this.$isLoading(true);

      let url = `/inventory/get_products_quantities/` + this.get_url_filters();

      this.$http
        .get(url)
        .then((response) => {
          const { items, items_count, pagination } = response.data;
          this.products = items;
          this.items_count = items_count;
          this.pagination = pagination;
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.change_is_forbidden(true);
            this.append_forbidden_error(
              "Make Sure You Have Permission To View Products"
            );
          }
          console.log(`Error while fetching products ! : ${e}`);
        })
        .finally(() => {
          this.$isLoading(false);
        });
    },
    get_by_filter(e) {
      this.filter_type = e.target.value;
      this.get_products();
    },
    search(e) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => {
        this.name_search = e.target.value;
        this.get_products();
      }, 800);
    },
    next_page() {
      if (this.pagination.next) {
        this.pagination.current_page += 1;
        this.get_products();
      }
    },
    previous_page() {
      if (this.pagination.previous) {
        this.pagination.current_page -= 1;
        this.get_products();
      }
    },
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  created() {
    this.get_products();
  },
};
</script>

<style lang="scss" scoped>
.stock {
  width: 100%;
  height: 100%;
}

.filters,
.search,
.indicators {
  display: flex;
  flex-wrap: wrap;
}

.filters {
  margin: 1rem 0;
  gap: 2rem;

  label {
    flex: 1;
    font-size: 1.2rem;

    input[type="radio"] {
      transform: scale(1.5);
      margin: 0.22rem;
    }
  }
}

.search {
  gap: 0.45rem;

  & > label {
    flex: 3;

    input {
      width: 100%;
    }
  }

  .box {
    flex-basis: 80px;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: white;

    display: flex;
    justify-content: center; /* center flex items vertically */
    align-items: center; /* center flex items horizontally */

    &:nth-child(2) {
      background: $error-600;
    }

    &:nth-child(3) {
      background: $primary-400;
    }

    &:nth-child(4) {
      background: $success-700;
    }
  }
}

.table-wrapper {
  //min-height: 75%;
  //max-height: 75%;
  overflow: auto;
}

.pag {
  margin: 1% 0;
  position: relative;
  display: flex;

  & > * {
    flex: 1;
  }

  span {
    text-align: center;
  }
}
</style>
