<template>
  <div class="search-container">
    <input
      ref="search_input"
      v-model="search_val"
      @input="detect_search_type"
      @keypress.enter="detect_search_type"
      @focus="$store.dispatch('keyboard/set_active_input', $event.target)"
      :type="input_type || 'text'"
      :class="search_css_class"
      :style="search_css_style"
      :placeholder="$t('common.search_by_product_name_or_barcode')"
      class="search-bar-input"
    />
    <svg
      v-if="allow_icon"
      class="search-icon"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70841 12.75C9.76899 12.75 12.2501 10.2689 12.2501 7.20832C12.2501 4.14775 9.76899 1.66666 6.70841 1.66666C3.64784 1.66666 1.16675 4.14775 1.16675 7.20832C1.16675 10.2689 3.64784 12.75 6.70841 12.75Z"
        stroke="#1A202C"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8334 13.3333L11.6667 12.1667"
        stroke="#1A202C"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
// import { debounce } from "@/helpers";
import { is_valid_ean } from "@/js/sell_screen/scales.js";
import { mapGetters } from "vuex";
import { get_pattern_length, extract_ean_info } from "@/js/sell_screen/scales";
import { OrderItem } from "@/js/sell_screen/order/order_item";
import { search_products } from "@/js/services/api_services";
import { display_error_messages } from "@/js/utils";

export default {
  name: "search_products",
  props: [
    "parent_component",
    "allow_icon",
    "search_css_class",
    "search_css_style",
    "input_type",
    // custom operations before product pushed into the parents array
    // should return products array
    "search_mixin",
  ],
  computed: {
    ...mapGetters({
      settings: "general_settings/get_settings",
    }),
    parent() {
      return this.parent_component || this.$parent;
    },
    get_all_products() {
      // return products and cached_products
      return [...this.parent.products, ...this.parent.cached_products];
    },
  },
  data() {
    return {
      search_val: "",
      query: null,

      // scale vars
      product_code: null,
    };
  },
  methods: {
    reset_products() {
      this.parent.searched_products = [];

      this.search_val.length > 0
        ? (this.parent.sf = true)
        : (this.parent.sf = false);
    },
    clear_search_input() {
      this.search_val = "";
    },
    name_search(second_call = false) {
      /* if product not found and this function second_call raise product not found
       * and don't call the function again
       * */
      this.reset_products();

      const r = new RegExp(`^${this.search_val}`, "iu");

      const matched = this.get_all_products.filter((product) => {
        return r.test(product.name) || r.test(product.product.name);
      });

      if (matched.length > 0) {
        this.parent.searched_products = [...matched];
        return;
      }

      if (second_call && matched.length === 0) {
        this.alert_product_not_found();
        return;
      }
      this.get_products_request(null, () => {
        this.name_search(true);
      });
    },
    barcode_search(barcode, second_call = false) {
      console.log("barcode search");
      for (let product of this.get_all_products) {
        if (product.barcode === barcode || product.code === barcode) {
          this.parent.add_item(product);
          this.clear_search_input();
          return 0;
        }
      }
      if (!second_call) {
        this.get_products_request(null, () => {
          this.barcode_search(barcode, true);
        });
      }
    },
    scale_search(barcode) {
      /** Weighing Scale Search **/
      const pattern = this.settings.scale.pattern;
      const { product_code } = extract_ean_info(pattern, barcode);
      this.query = product_code; // changing search query in case if it's not cached to search by product code not the full barcode

      switch (get_pattern_length(pattern)) {
        case 13:
          this.add_ean_13(pattern, barcode);
          break;
        case 18:
          this.add_ean_18(pattern, barcode);
          break;
      }
    },
    add_ean_13(pattern, barcode, second_call = false) {
      const { product_code } = extract_ean_info(pattern, barcode);

      for (let product of this.get_all_products) {
        if (product_code === product.code) {
          const { scale_product } = extract_ean_info(pattern, barcode, product);

          this.parent.add_item(scale_product);
          this.clear_search_input();
          return 0;
        }
      }
      if (!second_call) {
        this.get_products_request(product_code, () => {
          this.add_ean_13(pattern, barcode, true);
        });
      }
    },
    add_ean_18(pattern, barcode, second_call = false) {
      const { product_code } = extract_ean_info(pattern, barcode);

      for (let product of this.get_all_products) {
        if (product_code === product.code) {
          const { scale_product } = extract_ean_info(pattern, barcode, product);

          this.parent.add_item(scale_product);
          this.clear_search_input();
          return 0;
        }
      }
      if (!second_call) {
        this.get_products_request(product_code, () => {
          this.add_ean_18(pattern, barcode, true);
        });
      }
    },
    detect_search_type() {
      this.query = this.search_val; // bind for other functions usage

      const barcode =
        !isNaN(this.query) && this.query.length >= 5 ? this.query : false;

      const scale_enabled = this.settings.scale.enabled;

      if (barcode && is_valid_ean(barcode) && scale_enabled) {
        this.scale_search(barcode);
      } else if (barcode) {
        this.barcode_search(barcode);
      } else {
        this.name_search();
      }
    },
    alert_product_not_found() {
      this.$store.dispatch("utils/push_alert", {
        message: `Product Not Found`,
        type: "danger",
      });
    },
    push_to_products_array(mixin, products) {
      /*
       * arg : mixin
       *       the functions that can edit the product before push
       * arg : product
       *       can be a single object or array
       * */

      let _products = [];

      if (Array.isArray(products)) {
        _products.push(...products);
      }

      for (let product of _products) {
        const order_item = new OrderItem(product);

        this.parent.cached_products.push(
          mixin ? mixin(order_item) : order_item
        );
      }
    },
    get_products_request(search_value = null, on_success_callback) {
      const search_val = search_value ? search_value : this.search_val;

      search_products(search_val).then(({ data, errors }) => {
        if (data && data.length > 0) {
          this.push_to_products_array(this.search_mixin, data);
          on_success_callback();
        }
        if (errors) {
          display_error_messages(errors);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;
  .search-bar-input {
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 500;

    line-height: 150%;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.6);
    background: #f8f9fc;
    border: 1px solid #e0e9f4;
    border-radius: 8px;
    padding: 1rem 2.2rem;
  }
  .search-icon {
    position: absolute;
    top: 25%;
    left: 5%;
    width: 22px;
    height: 22px;
  }
}
</style>
