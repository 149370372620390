import { fixed_float } from "@/helpers";
import { convert_itembox } from "@/js/sell_screen/itembox";
import { ProductPrice } from "@/js/counts";
import store from "@/store";

class OrderItem {
  #item_id = 0;
  #price_with_tax = 0;
  #addons;
  #addons_taxes = 0;
  #addons_subtotal = 0;
  #addons_total = 0;
  #total_discount = 0;

  constructor(
    product,
    addons,
    ordered_quantity,
    fixed_discount = 0,
    percentage_discount = 0,
    changed_price = 0
  ) {
    this.product = product;
    this.copyProperties(product);
    this.item_type = product.item_type || "product";
    this.subtotal = fixed_float(product.subtotal);
    this.taxes = product.tax || [];
    this.tax_included = product.tax_included;
    this.tax_total = fixed_float(product.tax_total);
    this.price_with_tax = fixed_float(product.price_with_tax);
    this.ordered_quantity = ordered_quantity || 1;
    this.changed_price = changed_price;

    // #ced9ff aka  $secondary-100
    this.color = product.color || "#ced9ff";

    // item box
    this._itembox_set = product.itembox_set || [];
    this.is_item_box = product.is_item_box || false;

    // promotions
    this.promo = product.promo;
    this.is_promo = false;

    // addons
    this.addons = addons || [];
    if (this.addons.length > 0) {
      this.calculate_addons();
    }

    // discount
    this.discount_after =
      store.getters["general_settings/get_settings"].discount_type ===
      "after_tax";

    this.fixed_discount = fixed_discount;
    this.percentage_discount = percentage_discount;

    if (this.is_item_box) {
      // change item_box item based on product tax include or exclude
      this.changed_price = this.price_with_tax;
    }

    if (this.changed_price) {
      this.change_price();
    }
  }

  copyProperties(product) {
    const properties = [
      "id",
      "name",
      "cost",
      "price",
      "last_cost",
      "reorder_point",
      "low_stock_bool",
      "active",
      "quantity",
      "code",
      "barcode",
      "image",
    ];

    for (const property of properties) {
      if (Object.prototype.hasOwnProperty.call(product, property)) {
        this[property] = product[property];
      }
    }
  }

  calculate_addons() {
    /* reset previous addons calc*/
    this.addons_subtotal = 0;
    this.addons_taxes = 0;
    this.addons_total = 0;

    for (let addon of this.addons) {
      this.addons_subtotal += addon.chosen_type.subtotal;
      this.addons_taxes += addon.chosen_type.taxes;
      this.addons_total += addon.chosen_type.total;
    }
  }

  set_item_id(val) {
    this.#item_id = val;
  }

  get_item_id() {
    return this.#item_id;
  }

  set price_with_tax(val) {
    this.#price_with_tax = val;
  }

  get price_with_tax() {
    return fixed_float(this.#price_with_tax, 2);
  }

  get total_subtotal() {
    return fixed_float(this.subtotal * this.ordered_quantity);
  }

  get total_taxes() {
    return fixed_float(this.tax_total * this.ordered_quantity);
  }

  get total_price() {
    return fixed_float(this.price_with_tax * this.ordered_quantity);
  }

  set addons(addons) {
    this.#addons = addons;
  }
  get addons() {
    return this.#addons;
  }

  set addons_subtotal(val) {
    this.#addons_subtotal = val;
  }

  get addons_subtotal() {
    return fixed_float(this.#addons_subtotal);
  }

  set addons_taxes(val) {
    this.#addons_taxes = val;
  }

  get addons_taxes() {
    return fixed_float(this.#addons_taxes);
  }

  set addons_total(val) {
    this.#addons_total = val;
  }

  get addons_total() {
    return fixed_float(this.#addons_total);
  }

  get itembox_set() {
    return this._itembox_set.map((itembox) =>
      convert_itembox(itembox, this.product)
    );
  }

  has_itemboxes() {
    return this.itembox_set.length > 0;
  }

  reset_ordered_quantity() {
    this.ordered_quantity = 1;
  }

  reduce_product_quantity() {
    /**
     * reduce current product
     * quantity for reorder_point
     * to prevent refresh in order to get the new product qty
     * */
    this.quantity -= this.ordered_quantity;
  }

  reset_quantities() {
    this.reduce_product_quantity();
    this.reset_ordered_quantity();
  }

  change_price() {
    const product = new ProductPrice(
      this.taxes,
      this.changed_price,
      this.tax_included
    );

    this.tax_total = product.tax_total;
    this.subtotal = product.subtotal;
    this.price_with_tax = product.price_with_tax;
  }

  // DISCOUNT METHODS
  get total_discount() {
    return fixed_float(this.#total_discount, 2);
  }
  set total_discount(val) {
    this.#total_discount = val;
  }

  get is_item_discount() {
    return this.fixed_discount > 0 || this.percentage_discount > 0;
  }

  get_fixed_discount_amount(base_amount) {
    return this.fixed_discount > 0
      ? this.fixed_discount
      : (this.percentage_discount * base_amount) / 100;
  }

  set_discount() {
    if (this.discount_after) {
      this.total_discount = this.get_fixed_discount_amount(this.total_price);
    } else {
      this.total_discount = this.get_fixed_discount_amount(this.total_subtotal);
    }
  }

  reset_discount() {
    this.fixed_discount = 0;
    this.percentage_discount = 0;
    this.total_discount = 0;
  }
}

export { OrderItem };
