import store from "@/store";

export const display_error_messages = (errors) => {
  Object.entries(errors).forEach(([key, value]) => {
    value.forEach((message) => {
      // Handle the returned promise, even if you're not using it
      store
        .dispatch("utils/push_alert", {
          message: `${key}: ${message}`,
          type: "danger",
        })
        .then(() => {
          // You can add some logic here if needed after dispatch is complete
        })
        .catch((error) => {
          console.error("Error dispatching alert:", error);
        });
    });
  });
};

// Debounce function that returns a Promise
export function debounce_promise(func, delay) {
  let timer;
  return (...args) =>
    new Promise((resolve, reject) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
}

export function dev_log(message, trace_back) {
  /* log only on development mode*/
  if (process.env.NODE_ENV === "development") {
    console.debug(message, "trace_back", trace_back);
  }
}
