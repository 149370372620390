<template>
  <div class="full-height">
    <div class="wrapper full-height">
      <div class="row full-height justify-content-between">
        <div class="left">
          <h3>Price Tags Details</h3>
          <div class="container">
            <div class="prefix-inputs d-flex flex-row flex-wrap gap-2 mt-3">
              <input type="text" placeholder="Page Width" />
              <input type="text" placeholder="Page Height" />

              <input type="text" placeholder="Margin Top" />
              <input type="text" placeholder="Margin Bottom" />
              <input type="text" placeholder="Margin Left" />
              <input type="text" placeholder="Margin Right" />
            </div>
            <div
              class="checkboxes justify-content-start d-flex flex-row flex-wrap gap-2 mt-4"
            >
              <div class="form-check">
                <input
                  v-model="options.product_name"
                  class="form-check-input"
                  type="checkbox"
                  id="check-product-name"
                />
                <label class="form-check-label" for="check-product-name">
                  Product Name
                </label>
              </div>
              <div class="form-check">
                <input
                  v-model="options.display_price"
                  class="form-check-input"
                  type="checkbox"
                  id="check-display-price"
                />
                <label class="form-check-label" for="check-display-price">
                  Display Price
                </label>
              </div>
              <div class="form-check">
                <input
                  v-model="options.show_barcode"
                  @change="re_init_jsbarcode"
                  class="form-check-input"
                  type="checkbox"
                  id="check-show-barcode"
                />
                <label class="form-check-label" for="check-show-barcode">
                  Show Barcode
                </label>
              </div>
            </div>
          </div>
          <div class="container mt-3">
            <select class="form-select" v-model="options.barcode_type">
              <option value="0">Barcode Type</option>
              <option value="ean8">Ean 8</option>
              <option value="ean13">Ean 13</option>
              <!--              <option value="ean18">Ean 18</option>-->
            </select>
          </div>
          <div class="container mt-5 row gap-4">
            <div class="d-flex justify-content-between">
              <h5>Font Size</h5>
              <input
                v-model="options.font_size"
                @change="re_init_jsbarcode"
                min="18"
                max="30"
                type="range"
                class="w-50"
              />
            </div>
            <div class="d-flex justify-content-between">
              <h5>Barcode Width</h5>
              <input
                v-model="options.barcode_width"
                @change="re_init_jsbarcode"
                min="2"
                max="5"
                type="range"
                class="w-50"
              />
            </div>
            <div class="d-flex justify-content-between">
              <h5>Barcode Height</h5>
              <input
                v-model="options.barcode_height"
                @change="re_init_jsbarcode"
                min="100"
                max="200"
                type="range"
                class="w-50"
              />
            </div>
          </div>
          <div class="container mt-5 mb-5">
            <h5>Product</h5>
            <input
              v-model="selected_product"
              @input="search"
              @keypress="add_product(selected_product_obj)"
              class="search-input pos-input w-100"
              list="products-list"
              placeholder="Search for product"
            />
            <datalist id="products-list">
              <option
                v-for="product in products"
                :key="product.id"
                :value="product.name"
                :data-id="product.id"
              ></option>
            </datalist>

            <div class="container mt-5 d-flex gap-1">
              <button
                v-for="product in selected_products"
                :key="product.id"
                class="btn btn-primary"
                @click="remove_product(product.id)"
              >
                {{ product.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="right full-height">
          <h3>Preview</h3>

          <div id="barcodes-area" class="barcodes mt-3">
            <div
              v-for="product in selected_products"
              :key="product.id"
              class="product-barcode-box"
            >
              <!-- header -->
              <div class="d-flex justify-content-between">
                <span v-if="options.product_name">{{ product.name }}</span>
                <span v-if="options.display_price">{{ product.price }}</span>
              </div>
              <!-- header -->

              <!-- barcode -->
              <div>
                <svg
                  v-if="product.barcode"
                  class="barcode"
                  :jsbarcode-format="options.barcode_type"
                  :jsbarcode-value="product.barcode"
                  :jsbarcode-text="options.show_barcode ? product.barcode : ' '"
                  :jsbarcode-fontsize="options.font_size"
                  :jsbarcode-height="options.barcode_height"
                  :jsbarcode-width="options.barcode_width"
                ></svg>
                <h3 v-else class="text-center text-danger">
                  Product Has No Barcode
                </h3>
              </div>
              <!-- barcode -->
            </div>
          </div>
          <div class="mx-auto mt-5 w-75">
            <button
              @click="handle_printing"
              class="btn btn-block btn-outline-primary w-100"
            >
              Print Tags
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { search_products } from "@/js/services/api_services";
import { display_error_messages } from "@/js/utils";

export default {
  name: "PriceTags",
  computed: {},
  components: {},
  data() {
    return {
      selected_product: "",
      selected_product_obj: {},
      selected_products: [],
      options: {
        // checkboxes
        product_name: true,
        display_price: true,
        show_barcode: true,
        // dropdown
        barcode_type: "ean8",
        // sliders
        font_size: 18,
        barcode_height: 100,
        barcode_width: 2,
      },
      products: [],
    };
  },

  methods: {
    search() {
      if (this.selected_product.length > 0) {
        search_products(this.selected_product).then(({ data, errors }) => {
          if (data && data.length > 0) {
            this.products = data;
          }
          if (errors) {
            display_error_messages(errors);
          }
        });
      }
      this.select_product();
    },
    select_product() {
      try {
        const selectedOption = document.querySelector(
          `#products-list option[value="${this.selected_product}"]`
        );
        const productId = selectedOption.dataset.id;
        this.selected_product_obj = this.products.find(
          (product) => product.id === parseInt(productId)
        );

        selectedOption.blur(); // close the datalist
      } catch (error) {
        if (error instanceof TypeError) {
          console.error("Caught TypeError:", error.message);
        } else {
          console.error("Caught error:", error.message);
        }
      }
    },
    add_product(product) {
      const exists = this.selected_products.find((p) => p.id === product.id);
      if (!exists && product.id) {
        this.selected_products.push(product);
        this.selected_product_obj = {};
        this.selected_product = "";
      }
      this.re_init_jsbarcode();
    },
    remove_product(id) {
      this.selected_products = this.selected_products.filter(
        (product) => product.id !== id
      );
    },
    re_init_jsbarcode() {
      // reinitializing JsBarcode object because .barcode class inside a v-for
      this.$nextTick(() => {
        if (this.options.barcode_type === "0") {
          this.$store.dispatch("utils/push_alert", {
            message: "Please Select Barcode Type",
            type: "danger",
          });
          return;
        }
        try {
          JsBarcode(".barcode").init();
        } catch (error) {
          this.$store.dispatch("utils/push_alert", {
            message: error,
            type: "danger",
          });
        }
      });
    },
    // printing
    get_print_styles() {
      // Get all stylesheets HTML
      console.log("document: ", this.$style);
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      stylesHtml += `
      <style>
        @media print {
            body {
              visibility: hidden;
            }
            #print-area {
              visibility: visible;
              /*display: flex;*/
              /*position: absolute;*/
              left: 0;
              top: 0;
            }
          }
      </style>
      `;
      return stylesHtml;
    },
    handle_printing() {
      const barcodes_dom = document.getElementById("barcodes-area");
      console.log(" barcodes_dom ", barcodes_dom);

      let print_dialog = window.open(
        "",
        "",
        "left=300,top=0,width=800,height=500,toolbar=0,scrollbars=0,status=0"
      );
      print_dialog.document.write(`
        <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <!-- TODO LOAD SCOPED SCSS IN THIS VIEW -->
                ${this.get_print_styles()}
              <title>Printing Price Tags</title>
          </head>
          <body>
              <div id="print-area" class="w-100">
                    ${barcodes_dom.outerHTML}
              </div>
          </body>
        </html>
    `);
      setTimeout(() => {
        print_dialog.document.close();
        print_dialog.focus();
        print_dialog.print();
        // print_dialog.close(); // close main window on cancel dialog button
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/products/price_tags";
</style>
