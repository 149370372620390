<template>
  <div class="update-item-box pb-5 pb-md-0">
    <section_route
      :routes="[
        { name: $t('sidebar.item_box'), route: 'item_boxes' },
        { name: `${$t('common.edit')} ${product.name}`, route: '' },
      ]"
    />

    <!-- New Item Box Modal -->
    <div
      class="modal fade"
      id="add-modal"
      tabindex="-1"
      aria-labelledby="add-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title col">{{ $t("common.add_new") }}</h5>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="btn-close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <p class="col-md-4">{{ $t("common.name") }} :</p>
              <input
                v-model="new_item_box.name"
                class="col-md-6 pos-input"
                type="text"
              />

              <p class="col-md-4">{{ $t("common.count") }} :</p>
              <input
                v-model="new_item_box.count"
                class="col-md-6 pos-input"
                type="number"
              />

              <p class="col-md-4">{{ $t("common.unit_price") }} :</p>
              <input
                v-model="new_item_box.unit_of_price"
                class="col-md-6 pos-input"
                type="number"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="add_new_item" class="pos-primary-btn px-5 py-2">
              {{ $t("common.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End of New Item Box Modal -->

    <!-- Edit Item Box Modal -->
    <div
      class="modal fade"
      id="edit-modal"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title col">
              {{ $t("common.edit") }} {{ selected_item_box.name }}
            </h5>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="btn-close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row">
              <p class="col-md-4">{{ $t("common.name") }} :</p>
              <input
                v-model="selected_item_box.name"
                class="col-md-6 pos-input"
                type="text"
              />

              <p class="col-md-4">{{ $t("common.count") }} :</p>
              <input
                v-model="selected_item_box.count"
                class="col-md-6 pos-input"
                type="number"
              />

              <p class="col-md-4">{{ $t("common.unit_price") }} :</p>
              <input
                v-model="selected_item_box.unit_of_price"
                class="col-md-6 pos-input"
                type="number"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="update_item" class="pos-primary-btn px-5 py-2">
              {{ $t("common.edit") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End of Edit Item Box Modal -->

    <div class="mid mt-5">
      <div class="row align-items-baseline">
        <h3 class="col-12 col-md-auto text-center">
          {{ $t("common.product_name") }} :
        </h3>

        <!-- search input -->
        <div class="col-12 col-md mt-3 mt-md-0">
          <input
            type="text"
            class="search-input pos-input w-100"
            :value="product.name"
            disabled
          />
        </div>
        <!-- search input -->

        <!-- add new item box button -->
        <div class="col-12 col-md-2 mt-3 mt-md-0">
          <button
            data-bs-toggle="modal"
            data-bs-target="#add-modal"
            title="Add a new Item Box to this product"
            class="btn btn-outline-primary w-100"
          >
            {{ $t("common.add") }}
          </button>
        </div>
        <!-- add new item box button -->
      </div>
      <div class="table-wrapper">
        <table class="primary-table mt-5" v-if="item_boxes.length > 0">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("common.name") }}</th>
              <th>{{ $t("common.count") }}</th>
              <th>{{ $t("common.unit_price") }}</th>
              <th style="width: 1rem">{{ $t("common.control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in item_boxes" :key="`item_${index}`">
              <td>{{ item.id || "" }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.count }}</td>
              <td>{{ item.unit_of_price }}</td>
              <td class="d-flex">
                <button
                  @click="
                    () => {
                      selected_item_box = { index: index, ...item };
                    }
                  "
                  class="btn btn-success mx-1"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-modal"
                  title="Add a new Item Box to this product"
                >
                  <img :src="$get_icon('edit')" alt="" />
                </button>
                <button @click="remove_item(item.id)" class="btn btn-danger">
                  <img :src="$get_icon('delete')" alt="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="wrapper row justify-content-end mx-1">
      <button
        @click="update_item_boxes"
        class="col-md-2 btn btn-outline-primary px-5"
      >
        {{ $t("common.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import { close_current_modal } from "@/helpers";

export default {
  name: "UpdateItemBox",
  components: { section_route },
  data() {
    return {
      product: {
        name: "",
      },
      // bind when user clicks on edit icon
      selected_item_box: {
        name: "",
        count: null,
        unit_of_price: null,
      },

      // to bind new item box modal variables
      new_item_box: {
        name: "",
        count: null,
        unit_of_price: null,
      },
      item_boxes: [],
    };
  },
  methods: {
    remove_item(product_id) {
      const index = this.item_boxes.findIndex((p) => p.id === product_id);
      this.item_boxes.splice(index, 1);
    },
    add_new_item() {
      this.item_boxes.push(this.new_item_box);
      this.new_item_box = {
        name: "",
        count: null,
        unit_of_price: null,
      };
      close_current_modal();
    },
    update_item() {
      this.item_boxes.splice(this.selected_item_box.index, 1, {
        id: this.selected_item_box.id || null,
        name: this.selected_item_box.name,
        count: this.selected_item_box.count,
        unit_of_price: this.selected_item_box.unit_of_price,
      });
      close_current_modal();
    },
    update_item_boxes() {
      const form = {
        name: this.product.name,
        product: this.product.id,
        item_boxes: this.item_boxes,
      };
      this.$http
        .put(`/inventory/item_box/${this.product.id}`, form)
        .then(() => {
          this.$router.push({ name: "item_boxes" });
        })
        .catch((e) => {
          console.log(`Error while Updating item boxes ! : ${e}`);
        });
    },
    get_item_boxes() {
      this.$http
        .get(`/inventory/product_item_boxes/${this.$route.params.id}`)
        .then((response) => {
          this.product = {
            id: response.data.id,
            name: response.data.name,
          };
          this.item_boxes = response.data.itembox_set;
        });
    },
  },
  mounted() {
    this.get_item_boxes();
  },
};
</script>

<style lang="scss" scoped>
.update-item-box {
  width: 100%;
  height: calc(100% - 1.2rem);
  position: relative;

  background: $main-white;
  margin: 0.55rem 0;
  border-radius: 8px;

  .mid {
    min-height: 85%;
    .table-wrapper {
      min-height: 93%;
      max-height: 93%;
      overflow: scroll;
    }
  }

  .modal {
    .modal-body > .row * {
      margin-top: 1rem;
    }
  }
}
</style>
