import { Order } from "@/js/sell_screen/order/order";
import { OrderDiscount } from "@/js/sell_screen/discount/discount";

// todo: save the order in the localstorage
const state = {
  original_order: new Order(),
  // if there is a discount or so
  modified_order: new Order(),
  invoice_list: {
    active_index: -1,
  },
};

const actions = {
  set_active_index({ commit }, index) {
    commit("set_active_index", index);
  },
  set_current_order({ commit }, order) {
    commit("set_current_order", order);
    commit("count_order");
  },
  delete_current_order({ commit }) {
    commit("delete_current_order");
    commit("count_order");
  },
  // Order Client
  set_order_client: ({ commit }, client_obj) => {
    commit("set_order_client", client_obj);
  },
  reset_order_client: ({ commit }) => {
    commit("reset_order_client");
  },
  // order_item
  add_order_item({ commit }, order_item) {
    commit("add_order_item", order_item);
    commit("count_order");
  },
  update_order_item({ commit }, payload) {
    commit("update_order_item", payload);
    commit("count_order");
  },
  delete_order_item({ commit }) {
    commit("delete_order_item");
    commit("count_order");
  },
  set_order_item_quantity({ commit }, quantity) {
    commit("set_order_item_quantity", quantity);
    commit("count_order");
  },
  increase_order_item_quantity({ commit }, quantity) {
    commit("increase_order_item_quantity", quantity);
    commit("count_order");
  },

  // discount
  add_cart_fixed_discount({ commit }, value) {
    commit("add_cart_fixed_discount", value);
    commit("count_order");
  },
  add_cart_percentage_discount({ commit }, value) {
    commit("add_cart_percentage_discount", value);
    commit("count_order");
  },
  add_order_item_fixed_discount({ commit }, discount_value) {
    commit("add_order_item_fixed_discount", discount_value);
    commit("count_order");
  },
  add_order_item_percentage_discount({ commit }, discount_value) {
    commit("add_order_item_percentage_discount", discount_value);
    commit("count_order");
  },
  reset_order_discount: ({ commit }) => {
    commit("reset_order_discount");
    commit("count_order");
  },
  reset_order_item_discount: ({ commit }) => {
    commit("reset_order_item_discount");
    commit("count_order");
  },

  // addons
  override_order_item_addons({ commit }, addons) {
    commit("override_order_item_addons", addons);
    commit("count_order");
  },
};

const mutations = {
  set_current_order: (state, order) => {
    state.original_order = new OrderDiscount(
      order.client,
      order.datetime,
      order.items,
      order.cart_fixed_discount,
      order.cart_discount_percentage,
      order.total_discount
    );
  },
  set_active_index: (state, index) => {
    // toggle the item if exists
    if (index === state.invoice_list.active_index) {
      state.invoice_list.active_index = -1;
    } else {
      state.invoice_list.active_index = index;
    }
  },
  count_order: (state) => {
    const original = {
      client: state.original_order.client,
      datetime: state.original_order.datetime,
      items: state.original_order.items,
      cart_fixed_discount: state.original_order.cart_fixed_discount,
      cart_discount_percentage: state.original_order.cart_discount_percentage,
      total_discount: state.original_order.total_discount,
    };

    state.modified_order = new OrderDiscount(
      original.client,
      original.datetime,
      original.items,
      original.cart_fixed_discount,
      original.cart_discount_percentage,
      original.total_discount
    );
  },
  delete_current_order: (state) => {
    state.original_order = new Order();
  },
  // Order Client
  set_order_client: (state, client_obj) => {
    state.original_order.client = client_obj;
  },
  reset_order_client: (state) => {
    state.original_order.client = null;
  },

  // order_item
  add_order_item: (state, order_item) => {
    state.original_order.add_order_item(order_item);
  },
  update_order_item: (state, { order_item_index, order_item }) => {
    state.original_order.items.splice(order_item_index, 1, order_item);
  },
  delete_order_item: (state) => {
    state.original_order.items.splice(state.invoice_list.active_index, 1);
    state.invoice_list.active_index = -1;
  },
  set_order_item_quantity: (state, quantity) => {
    state.original_order.items[
      state.invoice_list.active_index
    ].ordered_quantity = quantity;
  },
  increase_order_item_quantity: (state, quantity) => {
    state.original_order.items[
      state.invoice_list.active_index
    ].ordered_quantity += quantity;
  },

  // Discount
  add_cart_fixed_discount: (state, value) => {
    state.original_order.cart_fixed_discount = value;
  },
  add_cart_percentage_discount: (state, value) => {
    state.original_order.cart_discount_percentage = value;
  },
  add_order_item_fixed_discount: (state, discount_value) => {
    state.original_order.items[state.invoice_list.active_index].fixed_discount =
      discount_value;
  },
  add_order_item_percentage_discount: (state, discount_value) => {
    state.original_order.items[
      state.invoice_list.active_index
    ].percentage_discount = discount_value;
  },
  reset_order_discount: (state) => {
    state.original_order.cart_fixed_discount = 0;
    state.original_order.cart_discount_percentage = 0;
  },
  reset_order_item_discount: (state) => {
    const current_item_index = state.invoice_list.active_index;
    state.original_order.items[current_item_index].fixed_discount = 0;
    state.original_order.items[current_item_index].percentage_discount = 0;
  },

  // addons
  override_order_item_addons: (state, addons) => {
    state.original_order.items[state.invoice_list.active_index].addons = addons;
  },
};
const getters = {
  get_order_client: (state) => {
    return state.original_order.client;
  },
  get_active_index: (state) => {
    return state.invoice_list.active_index;
  },
  get_active_item: (state) => {
    return state.original_order.items[state.invoice_list.active_index] || {};
  },
  get_current_order: (state) => {
    return state.modified_order;
  },
  get_order_item: () => (order_item_index) => {
    return state.modified_order.items[order_item_index];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
