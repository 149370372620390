<template>
  <div class="new-inventory-count">
    <!-- Quantity Modal -->
    <div
      class="modal fade"
      id="qtymodal"
      tabindex="-1"
      aria-labelledby="qtyModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <h4 class="modal-title w-100" id="qtyModalLabel">
              {{ $t("common.quantity") }}
              <span
                v-if="selected_item.name"
                class="text-success"
                style="font-size: 1.7rem"
              >
                {{ $t("common.to") }} {{ selected_item.name }}
              </span>
            </h4>

            <button
              ref="qty-close"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <!--              @keypress.enter="set_quantity"-->
            <input
              ref="qty"
              id="qty"
              type="text"
              pattern="[0-9]+"
              class="form-control"
              style="padding: 0.6rem"
            />
          </div>
          <div class="modal-footer">
            <button @click="set_quantity" class="btn btn-outline-primary px-5">
              {{ $t("common.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="focus_qty"
      id="qty-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#qtymodal"
    ></button>
    <!-- End of Quantity Modal -->

    <!-- Edit  Modal -->
    <div
      class="modal fade"
      id="editmodal"
      tabindex="-1"
      aria-labelledby="editModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header" style="border: none">
            <span
              v-if="selected_item.name"
              class="text-success"
              style="font-size: 1.7rem"
            >
              {{ selected_item.name }} {{ $t("common.quantity") }}
            </span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <input
              ref="edit_qty"
              type="text"
              pattern="[0-9]+"
              class="form-control"
              style="padding: 0.6rem"
            />
          </div>
          <div class="modal-footer">
            <button @click="edit_quantity" class="btn btn-outline-warning px-5">
              {{ $t("common.edit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="focus_qty"
      id="edit-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#editmodal"
    ></button>
    <!-- End of Edit Modal -->

    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.5rem">
              {{ $t("common.delete_confirm") }}
            </span>
            <span>
              {{ selected_item.name }}
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_item" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
          <!-- /.modal-footer -->
        </div>
      </div>
    </div>
    <button
      id="del-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#delmodal"
    ></button>
    <!-- End of ConfirmDelete Modal -->

    <div class="d-flex justify-content-between">
      <section_route
        :routes="[
          { name: $t('inventory.inventory_count'), route: 'inventory_count' },
          {
            name: $t('inventory.new_inventory_count'),
            route: '',
          },
        ]"
        class="pb-2"
      />

      <button @click="save" class="pos-primary-btn px-5">
        {{ $t("common.save") }}
      </button>
    </div>

    <div class="d-flex justify-content-center p-3">
      <vue_instant_search
        :on_select="add_item"
        :placeholder="$t('common.search_by_product_name_or_barcode')"
      />
    </div>

    <div class="table-wrapper">
      <table class="primary-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("common.product_name") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th style="width: 150px">{{ $t("common.control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{ id, name, quantity } in products" :key="id">
            <td>{{ id }}</td>
            <td>{{ name }}</td>
            <td>{{ quantity }}</td>
            <td
              class="d-flex flex-wrap gap-2 justify-content-around control-btns"
            >
              <img
                @click="
                  () => {
                    set_selected_item_by_id(id);
                    edit_dialog();
                  }
                "
                :src="$get_icon('edit')"
                class="btn btn-success"
                alt=""
              />
              <img
                @click="
                  () => {
                    set_selected_item_by_id(id);
                    delete_dialog();
                  }
                "
                :src="$get_icon('delete')"
                class="btn btn-danger"
                alt=""
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import vue_instant_search from "@/components/search/vue_instant_search.vue";
import { close_current_modal } from "@/helpers";
import { mapGetters } from "vuex";

export default {
  name: "NewInventoryCount",
  components: {
    section_route,
    vue_instant_search,
  },
  computed: {
    ...mapGetters("temporary", ["get_temp_object"]),
  },
  data() {
    return {
      products: [],
      selected_item: {},
    };
  },
  methods: {
    close_current_modal: close_current_modal,
    clear_qty() {
      this.$refs.qty.value = "";
    },
    add_item() {
      this.selected_item = this.get_temp_object;
      document.getElementById("qty-btn").click();
    },

    set_selected_item_by_id(id) {
      this.selected_item = this.products.find((i) => i.id === id);
    },
    focus_qty() {
      setTimeout(function () {
        document.querySelectorAll(".show .modal-body input")[0].focus();
      }, 480);
    },
    set_quantity() {
      if (this.$refs.qty.validity.valid) {
        this.selected_item.quantity = this.$refs.qty.value;
        this.products.push({
          id: this.selected_item.id,
          name: this.selected_item.name,
          quantity: this.selected_item.quantity,
          item_type: this.selected_item.item_type,
        });
        this.searched_products = [];
        this.close_current_modal();
        this.clear_qty();
      }
    },
    edit_quantity() {
      const qty = this.$refs.edit_qty.value;
      this.selected_item.quantity = qty;
      const index = this.products.findIndex(
        (e) => e.id === this.selected_item.id
      );
      this.products.splice(index, 1, {
        ...this.selected_item,
        quantity: qty,
      });
      this.close_current_modal();
    },
    edit_dialog() {
      document.getElementById("edit-modal-btn").click();
    },
    delete_dialog() {
      document.getElementById("del-modal-btn").click();
    },

    delete_item() {
      this.close_current_modal();
      this.products = this.products.filter(
        (product) => product.id !== this.selected_item.id
      );
      this.selected_item = {};
    },
    save() {
      const s_obj = this.products.map((product) => ({
        id: product.id,
        counted: product.quantity,
        item_type: product.item_type,
      }));
      if (s_obj.length <= 0) {
        this.$store.dispatch("utils/push_alert", {
          message: "Please Add Products ",
          type: "danger",
        });
        return;
      }

      this.$http
        .post("/inventory/add_inventory_count", {
          items: s_obj,
        })
        .then(() => {
          this.$router.push({ name: "inventory_count" });
        })
        .catch((e) => {
          console.log("Error while trying add new inventory count", e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-inventory-count {
  width: 100%;
  height: 100%;

  .table-wrapper {
    min-height: 75%;
    max-height: 75%;
    overflow: auto;

    input[type="checkbox"] {
      transform: scale(1.6);
    }

    .control-btns {
      img {
        max-width: 48px;
        max-height: 48px;
      }
    }
  }
}
</style>
