<template>
  <div class="new-transfer">
    <div class="sec-name">
      <span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.4999 12H3.66992"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <span
        @click="$router.push({ name: 'inventory_transfer' })"
        class="ms-1 fade-route"
      >
        {{ $t("inventory.inventory_transfer") }}
      </span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"
          stroke="#90A3BF"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span> {{ $t("inventory.new_transfer_operation") }} </span>
    </div>

    <div class="mt-1 w-100">
      <input
        @input="search_branch_products(from_branch.id, $event)"
        class="form-control w-75 mt-3"
        :placeholder="$t('inventory.product_want_move_to')"
        type="search"
      />
      <ul class="dropdown mt-2 d-none" style="width: 20%">
        <li
          v-for="product in products"
          :key="product.id"
          @click="
            set_current_product(product);
            add_product($event);
          "
          class="text-center"
        >
          {{ product.name }}
        </li>
      </ul>
    </div>

    <div class="table-wrapper mt-3">
      <table class="primary-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("inventory.from_product") }}</th>
            <th>{{ $t("inventory.to_product") }}</th>
            <th>{{ $t("common.quantity") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in added_items" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <input
                @input="search_branch_products(to_branch.id, $event)"
                class="form-control transparent"
                :placeholder="$t('common.search_by_product_name_or_barcode')"
                type="search"
              />
              <ul class="dropdown mt-2 d-none" style="width: 20%">
                <li
                  v-for="product in products"
                  :key="product.id"
                  @click="link_to_product(item.id, product, $event)"
                >
                  {{ product.name }}
                </li>
              </ul>
            </td>
            <td>
              <input
                @input="
                  (e) => {
                    item.quantity = parseFloat(e.target.value);
                  }
                "
                :value="item.quantity || 0"
                class="form-control transparent"
              />
            </td>
            <td style="width: 10%">
              <div class="d-flex justify-content-between">
                <button
                  @click="remove_product(item.id)"
                  class="btn btn-danger mx-1"
                >
                  <img :src="$get_icon('delete')" alt="" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="split"></div>
    <!-- /.split -->
    <div class="info">
      <div class="info-row">
        <p>{{ $t("inventory.from_branch") }}</p>

        <div class="dropdown">
          <button
            @click="set_from_branch({})"
            class="btn pos-input dropdown-toggle"
            type="button"
            id="from-branch-btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{
              from_branch.id
                ? from_branch.name
                : $t("inventory.select_a_branch")
            }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="from-branch-btn">
            <li @click="from_branch = {}">
              <a class="dropdown-item" href="#">{{
                $t("inventory.select_a_branch")
              }}</a>
            </li>
            <template v-for="{ id, name } in company_branches">
              <li
                v-if="to_branch.id !== id"
                :key="id"
                @click="set_from_branch({ id, name })"
                :class="from_branch.id === id ? 'active' : ''"
              >
                <a class="dropdown-item" href="#">{{ name }}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="info-row">
        <p>{{ $t("inventory.to_branch") }}</p>

        <div class="dropdown">
          <button
            @click="set_to_branch({})"
            type="button"
            id="to-branch-btn"
            class="btn pos-input dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{
              to_branch.id ? to_branch.name : $t("inventory.select_a_branch")
            }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="to-branch-btn">
            <li @click="to_branch = {}">
              <a class="dropdown-item" href="#">{{
                $t("inventory.select_a_branch")
              }}</a>
            </li>
            <template v-for="{ id, name } in company_branches">
              <li
                v-if="from_branch.id !== id"
                :key="id"
                @click="set_to_branch({ id, name })"
                :class="to_branch.id === id ? 'active' : ''"
              >
                <a class="dropdown-item" href="#">{{ name }}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <div class="info-row">
        <p>{{ $t("inventory.notes") }}</p>

        <input
          v-model="notes"
          type="text"
          class="pos-input"
          :placeholder="$t('inventory.adding_notes')"
        />
        <!-- /.pos-input -->
      </div>

      <!-- /.info -->
    </div>
    <div class="buttons">
      <button
        @click="$router.push({ name: 'inventory_transfer' })"
        id="cancel-btn"
        class="btn btn-danger"
      >
        {{ $t("common.cancel") }}
      </button>
      <button @click="create_new_transfer" class="pos-primary-btn p-3">
        {{ $t("inventory.transfer") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewTransfer",
  data() {
    return {
      products: [], // products that user searched and returned from the server

      selected_product: {
        id: null,
        name: null,
        quantity: 0,
      },
      added_items: [],
      company_branches: [],
      from_branch: {},
      to_branch: {},
      notes: "",

      timeout_id: null,
    };
  },
  methods: {
    get_company_branches() {
      this.$http.get(`/company/get_company_branches/`).then((response) => {
        this.company_branches = response.data;
      });
    },
    set_current_product(product) {
      // check if item quantity is updating to t it
      const item = this.added_items.find((i) => i.id === product.id);
      this.selected_product = {
        id: product.id,
        name: product.name,
        quantity: item ? item.quantity : 0,
      };
    },
    set_from_branch(branch) {
      const from_dropdown = document.getElementById("from-branch-btn");
      this.from_branch = branch;

      if (branch.id) {
        from_dropdown.classList.remove("not-valid");
      }
    },
    set_to_branch(branch) {
      const to_dropdown = document.getElementById("to-branch-btn");
      this.to_branch = branch;
      if (branch.id) {
        to_dropdown.classList.remove("not-valid");
      }
    },
    add_product(event) {
      const product_exists = this.added_items.find(
        (item) => item.id === this.selected_product.id
      );
      if (product_exists) {
        this.$store.dispatch("utils/push_alert", {
          message: "This Product Added already",
          type: "danger",
        });
      } else {
        this.added_items.push(this.selected_product);

        // hide dropdown
        const ul_dropdown_dom = event.target.closest("ul");
        ul_dropdown_dom.classList.add("d-none");
      }
    },
    update_product() {
      const index = this.added_items.findIndex(
        (p) => p.id === this.selected_product.id
      );
      console.log("index: ", index);
      if (index >= 0) {
        this.added_items.splice(index, 1, this.selected_product);
      }
    },
    remove_product(item_id) {
      this.added_items = this.added_items.filter((item) => item.id !== item_id);
    },
    create_stock_transfer_items() {
      let items = [];
      for (let item of this.added_items) {
        items.push({
          from_product: item.id,
          to_product: item.to_product_id,
          quantity: item.quantity,
        });
      }
      return items;
    },
    create_new_transfer() {
      const payload = {
        from_stock: this.from_branch.id,
        to_stock: this.to_branch.id,
        stock_transfer_items: this.create_stock_transfer_items(),
        notes: this.notes,
      };
      this.$http
        .post(`/inventory/stock_transfer/`, payload)
        .then(() => {
          this.$store.dispatch("utils/push_alert", {
            message: "New Transfer Order Created Successfully.",
            type: "success",
          });
          this.$router.push({ name: "inventory_transfer" });
        })
        .catch((errors) => {
          const errors_data = errors.response.data;
          for (let key in errors_data) {
            this.$store.dispatch("utils/push_alert", {
              message: `${key}: ${errors_data[key][0]}`,
              type: "danger",
            });
          }
          console.log(`Error while creating new transfer order ! : ${errors}`);
        });
    },
    debounce(fn, delay) {
      clearTimeout(this.timeout_id);
      this.timeout_id = setTimeout(() => {
        fn();
      }, delay);
    },
    show_input_dropdown(i_event) {
      const ul_dropdown_dom = i_event.target.nextElementSibling;
      ul_dropdown_dom.classList.remove("d-none");
    },
    hide_input_dropdown(i_event) {
      const ul_dropdown_dom = i_event.target.closest("ul");
      ul_dropdown_dom.classList.add("d-none");
    },

    search_branch_products(branch_id, event) {
      if (!this.validate_search()) {
        return;
      }

      const product_name = event.target.value;
      if (!product_name) return;

      this.$http
        .get(
          `/inventory/stock_transfer_search/?search=${product_name}&branch_id=${branch_id}`
        )
        .then((response) => {
          const data = response.data;
          if (data.length > 0) {
            this.products = data;
            this.show_input_dropdown(event);
          }
        });
    },
    branch_alert_notification(dom) {
      dom.classList.remove("not-valid");
      dom.classList.add("not-valid");

      this.debounce(() => {
        this.$store.dispatch("utils/push_alert", {
          message: "Please Select the Branches first",
          type: "danger",
        });
      }, 1000);
    },
    validate_search() {
      const from_branch_dropdown = document.getElementById("from-branch-btn");
      const to_branch_dropdown = document.getElementById("to-branch-btn");

      if (!this.from_branch.id && !this.to_branch.id) {
        this.branch_alert_notification(from_branch_dropdown);
        this.branch_alert_notification(to_branch_dropdown);
        return false;
      }

      if (!this.from_branch.id) {
        this.branch_alert_notification(from_branch_dropdown);
        return false;
      }

      if (!this.to_branch.id) {
        this.branch_alert_notification(to_branch_dropdown);
        return false;
      }

      return true;
    },
    link_to_product(product_id, to_product, event) {
      this.hide_input_dropdown(event);

      const li_element = event.target;
      const ul_element = li_element.parentNode;
      const search_input = ul_element.previousElementSibling;
      // setting the search input for what product name that user clicked
      search_input.value = li_element.textContent;

      const product_index = this.added_items.findIndex(
        (p) => p.id === product_id
      );
      this.added_items[product_index].to_product_id = to_product.id;
    },
  },
  mounted() {
    this.get_company_branches();
  },
};
</script>

<style lang="scss" scoped>
.new-transfer {
  width: 100%;
  height: 100%;

  // search bar component
  .search-bar-li {
    list-style: none;
  }
  ul.dropdown {
    display: block;
    position: absolute;
    z-index: 99999;
    background: $main-white;
    border: 1px solid $primary-100;
    border-radius: 5px;
    width: 100%;

    li {
      padding: 10px 5px;
      list-style: none;
    }
  }
  // end of search bar component
  .table-wrapper {
    min-height: 60%;
    max-height: 60%;
    overflow: auto;
    table {
      th,
      td {
        border: 1px solid $secondary-200;
        padding: 5px;
      }
    }

    input[type="checkbox"] {
      transform: scale(1.6);
    }
  }
  .split {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 20%;
    min-height: 20%;

    & > * {
      padding: 0.78rem;
    }

    .info-row {
      display: flex;
      gap: 3rem;
      width: 100%;

      p {
        flex-basis: 20%;
        font-size: 15px;
        font-weight: bold;
        color: $secondary-500;
      }
      input {
        flex-grow: 0;
        flex-basis: 80%;
        padding: 10px;
      }
    }
    .info-row div.dropdown {
      flex-basis: 80%;
      button {
        width: 80%;
        text-align: start;
      }
    }

    .pos-input {
      flex-grow: 3;
    }

    .pos-primary-btn {
      flex-grow: 1;
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    #cancel-btn {
      border: 1px solid $error-600;
      font-weight: bold;
    }
  }
}

.not-valid {
  border: 2px solid red;
  animation: shake 0.8s;
}
</style>
