<template>
  <div id="saved-orders">
    <div class="orders">
      <section_route
        :routes="[
          { name: $t('sidebar.sell'), route: 'sell_screen' },
          { name: $t('sell_screen.save_sale'), route: '' },
        ]"
      />

      <div
        v-for="(order, index) in get_saved_orders"
        @click="toggle_item(index)"
        :key="index"
        :class="{ 'item-active': selected_order_index === index }"
        class="order-box"
      >
        <div class="row">
          <p class="col-md-7" style="font-size: 15px">
            Items : {{ order.items.length }}
          </p>
          <p class="col-md-4" style="font-size: 12px; color: #90a3bf">
            {{ order.datetime | time_ago }}
          </p>
          <p class="col-md-12" style="font-size: 13px">
            Take Out ( {{ order.user }} )
          </p>
          <p class="col-md-6" style="font-size: 10px">
            {{ order.datetime | get_time }}
          </p>
          <p class="col-md-6" style="font-size: 10px" :key="key_to_update_time">
            {{ order.datetime | get_date }}
          </p>
        </div>
      </div>
    </div>
    <div class="order-items">
      <div class="items">
        <div class="table-wrapper mt-3">
          <table>
            <thead>
              <tr>
                <th>{{ $t("common.product_name") }}</th>
                <th>{{ $t("common.unit_price") }}</th>
                <th>{{ $t("common.price") }}</th>
                <th>{{ $t("common.quantity") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in selected_order.items"
                @click="toggle_item(item, index)"
                :key="index"
              >
                <td class="text-center text-success">
                  <img
                    v-if="item.is_promo"
                    :src="$get_icon('PromoItemIcon')"
                    alt=""
                  />
                  <p class="text-dark">{{ item.name }}</p>
                  <div
                    v-for="addon in item.addons"
                    :key="addon.name + addon.chosen_type.id"
                    class="success-700"
                  >
                    <p>
                      {{ addon.name }} -
                      {{ addon.chosen_type.name }}
                    </p>
                  </div>
                </td>

                <td>
                  <p>
                    {{ item.price_with_tax }}
                  </p>
                  <div
                    v-for="addon in item.addons"
                    :key="addon.name + addon.chosen_type.id"
                    class="success-700"
                  >
                    <p>{{ addon.chosen_type.price }}$</p>
                  </div>

                  <p
                    v-if="item.fixed_discount"
                    class="text-danger ms-1"
                    style="font-size: 14px"
                  >
                    - discount : {{ item.fixed_discount }}$
                  </p>
                  <p
                    v-else-if="item.percentage_discount"
                    class="text-danger ms-2"
                    style="font-size: 14px"
                  >
                    - discount : {{ item.percentage_discount }}%
                  </p>
                </td>

                <!--  price starts -->
                <td class="item-price">
                  <!-- if item qty increased calc price based on qty changes -->
                  <p>{{ item.total_price }}$</p>
                  <div
                    v-for="addon in item.addons"
                    :key="addon.name + addon.chosen_type.id"
                    class="success-700"
                  >
                    <p>{{ addon.chosen_type.total }}$</p>
                  </div>
                </td>
                <!--  price end -->
                <td class="quantity text-danger">
                  <p>x{{ item.ordered_quantity }}</p>
                  <span
                    v-for="addon in item.addons"
                    :key="addon.name + addon.chosen_type.id"
                    class="success-700"
                  >
                    <p>X{{ addon.chosen_type.qty }}</p>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="total">
          <div class="row">
            <p class="text-start col-md-5">{{ $t("common.subtotal") }} :</p>
            <p class="text-end primary-color col-md-7">
              {{ selected_order.subtotal }}
            </p>
            <p class="text-start col-md-5">{{ $t("common.tax") }} :</p>
            <p class="text-end primary-color col-md-7">
              {{ selected_order.tax }}
            </p>

            <p class="text-start col-md-5">{{ $t("common.discount") }} :</p>
            <p class="text-end primary-color col-md-7">
              {{ selected_order.total_discount }}
            </p>

            <hr />
            <p class="text-start col-md-5">{{ $t("common.total") }} :</p>
            <p class="text-end primary-color col-md-7">
              {{ selected_order.total }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="buttons mt-3 d-flex flex-row flex-wrap justify-content-between p-3 gap-2"
      >
        <button @click="set_order" class="select-btn col">
          {{ $t("common.chose") }}
        </button>
        <button @click="delete_saved_order" class="btn btn-danger delete-btn">
          {{ $t("common.delete") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import section_route from "@/components/section_route";

export default {
  name: "SavedOrders",
  components: {
    section_route,
  },
  computed: {
    ...mapGetters("saved_orders", ["get_saved_orders"]),
  },
  data() {
    return {
      interval: null,
      selected_order_index: -1,
      selected_order: {},
      key_to_update_time: 1,
    };
  },
  methods: {
    ...mapActions("saved_orders", [
      "delete_selected_saved_sale",
      "save_current_order",
      "delete_current_order",
    ]),
    delete_saved_order() {
      if (this.selected_order_index > -1) {
        this.delete_selected_saved_sale(this.selected_order_index);
        this.delete_current_order();
        this.selected_order_index = -1;
        this.selected_order = {};

        if (this.get_saved_orders.length <= 0) {
          this.$router.push({ name: "sell_screen" });
        }
      }
    },
    set_order() {
      if (this.selected_order_index > -1) {
        this.save_current_order(
          this.get_saved_orders[this.selected_order_index]
        );
        this.delete_selected_saved_sale(this.selected_order_index);
        this.selected_order_index = -1;
        this.selected_order = {};
      }
      this.$router.push({ name: "sell_screen" });
    },
    toggle_item(index) {
      this.selected_order = {};
      this.selected_order_index = index;
      this.selected_order = this.get_saved_orders[index];
    },
  },
  filters: {
    time_ago: function (date) {
      let msec = new Date().getTime() - new Date(date).getTime();
      const hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      const mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      const ss = Math.floor(msec / 1000);
      return hh + ":" + mm + ":" + ss;
    },
    get_time: function (date) {
      return new Date(date).toLocaleTimeString();
    },
    get_date: function (date) {
      return new Date(date).toLocaleDateString();
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.key_to_update_time += 1;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<!-- TODO : there is a bug effecting the whole website when adding scoped -->
<style lang="scss">
.item-active {
  background: #d6e4ff;
}
#saved-orders {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /*padding: 2rem;*/
  gap: 0.8rem;
  height: 90vh;

  .orders,
  .items {
    background: #f8f9fc;
    border-radius: 10px;
    padding: 1rem;
  }

  .order-items {
    display: flex;
    flex-direction: column;

    .items {
      background: #ffff;
      height: 85vh;
    }

    .buttons button {
      font-size: 1.6rem;
      font-weight: bold;
      padding: 1rem;
      border-radius: 10px;
    }
    .buttons {
      .select-btn {
        color: #ffffff;
        border: none;
        background: #3366ff;
        box-shadow: 4px 4px 10px rgba(11, 58, 197, 0.23);
      }
    }
  }

  .orders {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 0.5rem;

    .order-box {
      padding: 10px;
      height: 9rem;
      border: 1px solid #c3d4e9;
      border-radius: 5px;
      transition: all ease-in 0.5s;
      &:hover {
        cursor: pointer;
        background: rgba(212, 224, 239, 0.79);
      }
    }
  }
  .orders > div:first-child {
    grid-column: 1 / -1;
    font-size: 1.8rem;
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 62%;
    max-height: 62%;
    min-width: 21vw;
    max-width: 80vw;
    overflow-x: hidden;
    overflow-y: auto;
  }
  table {
    margin-top: 2px;
    text-align: center;

    thead {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      tr {
        th {
          padding: 2px 10px;
          color: rgba(26, 32, 44, 0.6);
          font-size: 12px;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        th:last-child {
          border: none;
        }
      }
    }
    tbody {
      //max-height: 29rem !important;
      overflow-y: auto;
      overflow-x: hidden;
      tr {
        width: 100px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        td {
          padding: 1rem;
        }
      }
      .item-price {
        color: #254edb;
      }
    }
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 2rem;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    //background-color: #3366ff;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  .total {
    margin: 0.4rem;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.03);
  }
}
</style>
