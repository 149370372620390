import { fixed_float } from "@/helpers";

class Order {
  #subtotal = 0;
  #tax = 0;
  #total = 0;
  #total_discount = 0;
  constructor(
    client = null,
    datetime = null,
    items = [],

    cart_fixed_discount,
    cart_discount_percentage
  ) {
    this.client = client;
    this.datetime = datetime;

    this.items = items;

    /*
     * IMPORTANT NOTE : discount members defined in the order and not the OrderDiscount
     * class Because we cannot initialize the vuex state with a class or value that depends
     * on another module state or getter and vuex will not access from module to another except RootState, GetterState
     * and these are not a clean code for this project design
     **/
    this.cart_fixed_discount = fixed_float(cart_fixed_discount) || 0;
    this.cart_discount_percentage = fixed_float(cart_discount_percentage) || 0;
    this.order_discount =
      this.cart_fixed_discount || this.cart_discount_percentage;
  }

  add_order_item(order_item) {
    this.items.push(order_item);
  }

  /*
  todo: change order_item.addons_[subtotal, tax,
   total] in order gets [subtotal, tax, total] when u are using AddonItem class
   */
  get subtotal() {
    this.#subtotal = this.items.reduce((acc, order_item) => {
      return acc + order_item.total_subtotal + order_item.addons_subtotal;
    }, 0);
    return fixed_float(this.#subtotal, 2);
  }
  set subtotal(val) {
    this.#subtotal = val;
  }

  get tax() {
    this.#tax = this.items.reduce((acc, order_item) => {
      return acc + order_item.total_taxes + order_item.addons_taxes;
    }, 0);
    return fixed_float(this.#tax, 2);
  }
  set tax(val) {
    this.#tax = val;
  }

  get total() {
    this.#total = this.items.reduce((acc, order_item) => {
      return acc + order_item.total_price + order_item.addons_total;
    }, 0);

    return fixed_float(this.#total, 2);
  }
  set total(val) {
    this.#total = val;
  }

  get total_discount() {
    return fixed_float(this.#total_discount, 2);
  }
  set total_discount(val) {
    this.#total_discount = val;
  }
}

export { Order };
