import { OrderItem } from "@/js/sell_screen/order/order_item";
import { round_decimal } from "@/helpers";

function order_item_addons_to_server(order_item) {
  /**
   * exclude unnecessary  types array
   * to lightweight the request
   * */
  const s_addons = [];
  if (order_item.addons) {
    for (let addon of order_item.addons) {
      const cleaned_addon = {
        ...addon.chosen_type,
        qty: parseFloat(addon.chosen_type.qty),
        subtotal: round_decimal(addon.chosen_type.subtotal),
        taxes: round_decimal(addon.chosen_type.taxes),
        price: round_decimal(addon.chosen_type.price),
      };
      s_addons.push(cleaned_addon);
    }
  }
  return s_addons;
}

function order_item_to_server(order_item, server_addons) {
  /**
   * optimizing order_items, order_item addons
   * to meet server acceptable criteria
   *
   * **/
  // console.warn(order_item);
  return {
    product_id: order_item.id,

    quantity: order_item.ordered_quantity,
    subtotal: order_item.total_subtotal,
    tax_total: order_item.total_taxes,
    price_with_tax: order_item.price_with_tax,
    total: order_item.total_price,

    discount: order_item.fixed_discount,
    discount_in_percentage: order_item.percentage_discount,
    addons: server_addons,

    is_item_box: order_item.is_item_box,
  };
}

function clean_order_items(order_items) {
  /**
   * iterate through order_items
   * to optimize and meet server order_items criteria
   * */
  let cleaned_order_items = [];
  for (let order_item of order_items) {
    const addons = order_item_addons_to_server(order_item);
    cleaned_order_items.push(order_item_to_server(order_item, addons));
  }
  /** End Of OrderItems Iteration */
  return cleaned_order_items;
}

function copy_order_item(order_item) {
  return new OrderItem(
    order_item.product,
    // deep copy the addons to prevent multiple discount and changing the original addons price before discount
    structuredClone(order_item.addons),
    order_item.ordered_quantity,
    order_item.fixed_discount,
    order_item.percentage_discount,
    order_item.changed_price
  );
}

export { clean_order_items, copy_order_item };
