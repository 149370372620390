<template>
  <div class="new-item-box pb-5 pb-md-0">
    <section_route
      :routes="[
        { name: $t('sidebar.item_box'), route: 'item_boxes' },
        { name: $t('products.new_item_box'), route: '' },
      ]"
    />

    <!-- New Item Box Modal -->
    <div
      class="modal fade"
      id="add-modal"
      tabindex="-1"
      aria-labelledby="add-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title col">{{ $t("common.add_new") }}</h5>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              class="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <p class="col-md-4">{{ $t("common.name") }} :</p>
              <input
                v-model="new_item_box.name"
                class="col-md-6 pos-input"
                type="text"
              />

              <p class="col-md-4">{{ $t("common.count") }} :</p>
              <input
                v-model="new_item_box.count"
                class="col-md-6 pos-input"
                type="number"
              />

              <p class="col-md-4">{{ $t("common.unit_price") }} :</p>
              <input
                v-model="new_item_box.unit_of_price"
                class="col-md-6 pos-input"
                type="number"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="add_new_item" class="pos-primary-btn px-5 py-2">
              {{ $t("common.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- New Item Box Modal -->

    <div class="mid mt-5">
      <div class="row align-items-baseline">
        <h3 class="col-12 col-md-auto text-center">
          {{ $t("common.product_name") }} :
        </h3>
        <vue_instant_search
          class="vue-search-comp col-12 mt-3 mt-md-0"
          :placeholder="$t('common.search_by_product_name_or_barcode')"
        />
        <!-- add new item box button -->
        <div class="col-12 col-md-2 mt-3 mt-md-0">
          <button
            data-bs-toggle="modal"
            data-bs-target="#add-modal"
            title="Add a new Item Box to this product"
            class="btn btn-outline-primary w-100"
          >
            {{ $t("common.add") }}
          </button>
        </div>
        <!-- add new item box button -->
      </div>
      <div class="table-wrapper">
        <table class="primary-table mt-5" v-if="item_boxes.length > 0">
          <thead>
            <tr>
              <th>{{ $t("common.name") }}</th>
              <th>{{ $t("common.count") }}</th>
              <th>{{ $t("common.unit_price") }}</th>
              <th style="width: 1rem">{{ $t("common.control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in item_boxes" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.count }}</td>
              <td>{{ item.unit_of_price }}</td>
              <td>
                <button
                  @click="remove_item(item.id)"
                  class="btn btn-outline-danger"
                >
                  {{ $t("common.remove") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row justify-content-end mx-1">
      <button @click="save" class="col-md-2 btn btn-outline-primary px-5">
        {{ $t("common.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import vue_instant_search from "@/components/search/vue_instant_search.vue";
import { close_current_modal } from "@/helpers";
import { mapGetters } from "vuex";

export default {
  name: "CreateItemBox",
  components: { section_route, vue_instant_search },
  computed: {
    ...mapGetters("temporary", ["get_temp_object"]),
  },
  data() {
    return {
      // used to bind search object
      products: [],
      // to bind new item box modal variables
      new_item_box: {
        name: "",
        count: null,
        unit_of_price: null,
      },
      item_boxes: [],
    };
  },
  methods: {
    add_new_item() {
      this.item_boxes.push(this.new_item_box);
      this.new_item_box = {
        name: "",
        count: null,
        unit_of_price: null,
      };
      close_current_modal();
    },
    remove_item(product_id) {
      const index = this.item_boxes.findIndex((p) => p.id === product_id);
      this.item_boxes.splice(index, 1);
    },
    save() {
      if (!this.get_temp_object && !this.get_temp_object.id) {
        return;
      }
      const product = this.get_temp_object;
      const form = {
        product: product.id,
        item_boxes: this.item_boxes,
      };
      this.$http
        .post(`/inventory/item_box`, form)
        .then(() => {
          this.$router.push({ name: "item_boxes" });
        })
        .catch((e) => {
          console.log(`Error while creating new item boxes ! : ${e}`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-item-box {
  width: 100%;
  height: calc(100% - 1.2rem);
  position: relative;

  background: $main-white;
  margin: 0.55rem 0;
  border-radius: 8px;

  .mid {
    min-height: 85%;
    .table-wrapper {
      min-height: 93%;
      max-height: 93%;
      overflow: scroll;
    }
    @media (min-width: 280px) {
      ::v-deep .main form.searchbox.sbx-custom {
        width: 100%;
      }
    }
    @media (min-width: 768px) {
      .vue-search-comp {
        flex: 1;
      }
    }
  }

  .modal {
    .modal-body > .row * {
      margin-top: 1rem;
    }
  }
}
</style>
