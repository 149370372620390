<template>
  <div class="payment">
    <div id="alert-window"></div>

    <!-- Button trigger modal -->
    <button
      ref="change_alert"
      type="button"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
    ></button>

    <!-- CHANGE MODAL -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header"></div>
          <div
            class="modal-body p-3 d-flex flex-row justify-content-center align-items-baseline align-baseline gap-3"
          >
            <h1>{{ $t("common.change") }} :</h1>
            <h2 class="text-danger">{{ change }}</h2>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="submit_order"
              class="btn col-md-12 py-4 btn-primary"
              data-bs-dismiss="modal"
              style="font-size: 1.8rem"
            >
              {{ $t("common.ok") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF CHANGE MODAL -->

    <div class="keypad-modal w-100">
      <div
        class="modal fade"
        id="payment-modal"
        tabindex="-2"
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
      >
        <button
          type="button"
          class="btn-close d-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div class="modal-dialog">
          <div class="modal-content">
            <payment_keypad />
          </div>
        </div>
      </div>
    </div>
    <!-- /.keypad -->

    <i
      class="d-none"
      id="payment-modal-btn"
      data-bs-toggle="modal"
      data-bs-target="#payment-modal"
    ></i>

    <div class="wrapper">
      <section_route
        :routes="[
          { name: $t('sidebar.sell'), route: 'sell_screen' },
          { name: $t('sell_screen.payments'), route: '' },
        ]"
      />
      <div class="payments mt-3">
        <div class="payment-cards">
          <div
            v-for="payment in global"
            @click="payment_method(payment)"
            :key="payment.id"
            class="card"
          >
            <span v-if="payment.name === 'cash'" class="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.9496 11.0667C31.2329 7.45003 28.5496 5.8667 24.8163 5.8667H10.1829C5.78294 5.8667 2.84961 8.0667 2.84961 13.2V21.7834C2.84961 25.4834 4.36628 27.65 6.86628 28.5834C7.23294 28.7167 7.63294 28.8334 8.04961 28.9C8.71628 29.05 9.43294 29.1167 10.1829 29.1167H24.8329C29.2329 29.1167 32.1663 26.9167 32.1663 21.7834V13.2C32.1663 12.4167 32.0996 11.7167 31.9496 11.0667ZM9.21628 20C9.21628 20.6834 8.64961 21.25 7.96628 21.25C7.28294 21.25 6.71628 20.6834 6.71628 20V15C6.71628 14.3167 7.28294 13.75 7.96628 13.75C8.64961 13.75 9.21628 14.3167 9.21628 15V20ZM17.4996 21.9C15.0663 21.9 13.0996 19.9334 13.0996 17.5C13.0996 15.0667 15.0663 13.1 17.4996 13.1C19.9329 13.1 21.8996 15.0667 21.8996 17.5C21.8996 19.9334 19.9329 21.9 17.4996 21.9ZM28.2663 20C28.2663 20.6834 27.6996 21.25 27.0163 21.25C26.3329 21.25 25.7663 20.6834 25.7663 20V15C25.7663 14.3167 26.3329 13.75 27.0163 13.75C27.6996 13.75 28.2663 14.3167 28.2663 15V20Z"
                  fill="#3366FF"
                />
                <path
                  d="M37.1663 18.1999V26.7832C37.1663 31.9166 34.2329 34.1332 29.8163 34.1332H15.1829C13.9329 34.1332 12.8163 33.9499 11.8496 33.5832C11.0663 33.2999 10.3829 32.8832 9.83295 32.3499C9.53295 32.0666 9.76628 31.6166 10.1829 31.6166H24.8163C30.9829 31.6166 34.6496 27.9499 34.6496 21.7999V13.1999C34.6496 12.7999 35.0996 12.5499 35.3829 12.8499C36.5163 14.0499 37.1663 15.7999 37.1663 18.1999Z"
                  fill="#3366FF"
                />
              </svg>
            </span>
            <span v-else-if="payment.name === 'credit'" class="icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.5325 27.9166C35.9825 32.3499 32.3491 35.9832 27.9158 36.5332C25.2325 36.8666 22.7325 36.1332 20.7825 34.6999C19.6658 33.8832 19.9325 32.1499 21.2658 31.7499C26.2825 30.2332 30.2325 26.2666 31.7658 21.2499C32.1658 19.9332 33.8991 19.6666 34.7158 20.7666C36.1325 22.7332 36.8658 25.2332 36.5325 27.9166Z"
                  fill="#27BCD4"
                />
                <path
                  d="M16.6507 3.33325C9.30065 3.33325 3.33398 9.29992 3.33398 16.6499C3.33398 23.9999 9.30065 29.9666 16.6507 29.9666C24.0006 29.9666 29.9673 23.9999 29.9673 16.6499C29.9506 9.29992 24.0006 3.33325 16.6507 3.33325ZM15.084 14.7833L19.1007 16.1833C20.5507 16.6999 21.2507 17.7166 21.2507 19.2833C21.2507 21.0833 19.8173 22.5666 18.0673 22.5666H17.9173V22.6499C17.9173 23.3333 17.3507 23.8999 16.6673 23.8999C15.984 23.8999 15.4173 23.3333 15.4173 22.6499V22.5499C13.5673 22.4666 12.084 20.9166 12.084 18.9833C12.084 18.2999 12.6507 17.7333 13.334 17.7333C14.0173 17.7333 14.584 18.2999 14.584 18.9833C14.584 19.5833 15.0173 20.0666 15.5507 20.0666H18.0507C18.434 20.0666 18.734 19.7166 18.734 19.2833C18.734 18.6999 18.634 18.6666 18.2507 18.5333L14.234 17.1333C12.8007 16.6333 12.084 15.6166 12.084 14.0333C12.084 12.2333 13.5173 10.7499 15.2673 10.7499H15.4173V10.6833C15.4173 9.99992 15.984 9.43325 16.6673 9.43325C17.3507 9.43325 17.9173 9.99992 17.9173 10.6833V10.7833C19.7673 10.8666 21.2507 12.4166 21.2507 14.3499C21.2507 15.0333 20.684 15.5999 20.0007 15.5999C19.3173 15.5999 18.7507 15.0333 18.7507 14.3499C18.7507 13.7499 18.3173 13.2666 17.784 13.2666H15.284C14.9007 13.2666 14.6007 13.6166 14.6007 14.0499C14.584 14.6166 14.684 14.6499 15.084 14.7833Z"
                  fill="#27BCD4"
                />
              </svg>
            </span>
            <h2 v-if="payment.name === 'cash'">Cash</h2>
            <h2 v-else>{{ payment.name }}</h2>
          </div>
          <!-- custom payments -->
          <div
            v-for="payment in custom"
            @click="payment_method(payment)"
            :key="payment.id"
            class="card"
          >
            <h2>{{ payment.name }}</h2>
          </div>
          <!-- end of custom payments -->

          <!--          <div class="card">-->
          <!--            <span class="icon">-->
          <!--              <svg-->
          <!--                width="40"-->
          <!--                height="40"-->
          <!--                viewBox="0 0 40 40"-->
          <!--                fill="none"-->
          <!--                xmlns="http://www.w3.org/2000/svg"-->
          <!--              >-->
          <!--                <path-->
          <!--                  d="M33.3327 19.9999V29.9999C33.3327 33.6833 30.3493 36.6666 26.666 36.6666H13.3327C9.64935 36.6666 6.66602 33.6833 6.66602 29.9999V19.9999C6.66602 19.0833 7.41602 18.3333 8.33268 18.3333H11.616C12.5327 18.3333 13.2827 19.0833 13.2827 19.9999V25.2333C13.2827 26.4666 13.966 27.5999 15.0493 28.1833C15.5327 28.4499 16.066 28.5833 16.616 28.5833C17.2493 28.5833 17.8827 28.3999 18.4327 28.0333L20.016 26.9999L21.4827 27.9833C22.4993 28.6666 23.7993 28.7499 24.8827 28.1666C25.9827 27.5833 26.666 26.4666 26.666 25.2166V19.9999C26.666 19.0833 27.416 18.3333 28.3327 18.3333H31.666C32.5827 18.3333 33.3327 19.0833 33.3327 19.9999Z"-->
          <!--                  fill="#FF7460"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M35.8327 11.6666V13.3333C35.8327 15.1666 34.9493 16.6666 32.4993 16.6666H7.49935C4.94935 16.6666 4.16602 15.1666 4.16602 13.3333V11.6666C4.16602 9.83325 4.94935 8.33325 7.49935 8.33325H32.4993C34.9493 8.33325 35.8327 9.83325 35.8327 11.6666Z"-->
          <!--                  fill="#FF7460"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M19.4 8.33324H10.2C9.6333 7.71657 9.64997 6.76657 10.25 6.16657L12.6166 3.7999C13.2333 3.18324 14.25 3.18324 14.8666 3.7999L19.4 8.33324Z"-->
          <!--                  fill="#FF7460"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M29.784 8.33324H20.584L25.1173 3.7999C25.734 3.18324 26.7507 3.18324 27.3673 3.7999L29.734 6.16657C30.334 6.76657 30.3507 7.71657 29.784 8.33324Z"-->
          <!--                  fill="#FF7460"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M23.2837 18.3333C24.2004 18.3333 24.9504 19.0833 24.9504 19.9999V25.2166C24.9504 26.5499 23.4671 27.3499 22.3671 26.5999L20.8671 25.5999C20.3171 25.2333 19.6004 25.2333 19.0337 25.5999L17.4671 26.6333C16.3671 27.3666 14.9004 26.5666 14.9004 25.2499V19.9999C14.9004 19.0833 15.6504 18.3333 16.5671 18.3333H23.2837Z"-->
          <!--                  fill="#FF7460"-->
          <!--                />-->
          <!--              </svg>-->
          <!--            </span>-->
          <!--            &lt;!&ndash; /.icon &ndash;&gt;-->
          <!--            <h2>Gift Card</h2>-->
          <!--            &lt;!&ndash; /.text &ndash;&gt;-->
          <!--          </div>-->
          <!--          &lt;!&ndash; /.card &ndash;&gt;-->
          <!--          <div class="card">-->
          <!--            <span class="icon">-->
          <!--              <svg-->
          <!--                width="40"-->
          <!--                height="40"-->
          <!--                viewBox="0 0 40 40"-->
          <!--                fill="none"-->
          <!--                xmlns="http://www.w3.org/2000/svg"-->
          <!--              >-->
          <!--                <path-->
          <!--                  d="M20 0L21.0467 0.0274086L22.0906 0.109562L23.1287 0.246233L24.1582 0.437048L25.1764 0.681484L26.1803 0.97887L27.1674 1.32839L28.1347 1.72909L29.0798 2.17987L30 2.67949L30.8928 3.22659L31.7557 3.81966L32.5864 4.45708L33.3826 5.1371L34.1421 5.85786L34.8629 6.61739L35.5429 7.41359L36.1803 8.2443L36.7734 9.10722L37.3205 10L37.8201 10.9202L38.2709 11.8653L38.6716 12.8326L39.0211 13.8197L39.3185 14.8236L39.563 15.8418L39.7538 16.8713L39.8904 17.9094L39.9726 18.9533L40 20L39.9726 21.0467L39.8904 22.0906L39.7538 23.1287L39.563 24.1582L39.3185 25.1764L39.0211 26.1803L38.6716 27.1674L38.2709 28.1347L37.8201 29.0798L37.3205 30L36.7734 30.8928L36.1803 31.7557L35.5429 32.5864L34.8629 33.3826L34.1421 34.1421L33.3826 34.8629L32.5864 35.5429L31.7557 36.1803L30.8928 36.7734L30 37.3205L29.0798 37.8201L28.1347 38.2709L27.1674 38.6716L26.1803 39.0211L25.1764 39.3185L24.1582 39.563L23.1287 39.7538L22.0906 39.8904L21.0467 39.9726L20 40L18.9533 39.9726L17.9094 39.8904L16.8713 39.7538L15.8418 39.563L14.8236 39.3185L13.8197 39.0211L12.8326 38.6716L11.8653 38.2709L10.9202 37.8201L10 37.3205L9.10722 36.7734L8.2443 36.1803L7.41359 35.5429L6.61739 34.8629L5.85786 34.1421L5.1371 33.3826L4.45708 32.5864L3.81966 31.7557L3.22659 30.8928L2.67949 30L2.17987 29.0798L1.72909 28.1347L1.32839 27.1674L0.97887 26.1803L0.681484 25.1764L0.437048 24.1582L0.246233 23.1287L0.109562 22.0906L0.0274086 21.0467L0 20L0.0274086 18.9533L0.109562 17.9094L0.246233 16.8713L0.437048 15.8418L0.681484 14.8236L0.97887 13.8197L1.32839 12.8326L1.72909 11.8653L2.17987 10.9202L2.67949 10L3.22659 9.10722L3.81966 8.2443L4.45708 7.41359L5.1371 6.61739L5.85786 5.85786L6.61739 5.1371L7.41359 4.45708L8.2443 3.81966L9.10722 3.22659L10 2.67949L10.9202 2.17987L11.8653 1.72909L12.8326 1.32839L13.8197 0.97887L14.8236 0.681484L15.8418 0.437048L16.8713 0.246233L17.9094 0.109562L18.9533 0.0274086L20 0Z"-->
          <!--                  fill="#FFB847"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M19.1626 4.02227L20 4.00034L20.8374 4.02227L21.6724 4.08799L22.5029 4.19733L23.3265 4.34997L24.141 4.54552L24.9442 4.78342L25.7338 5.06303L26.5077 5.38359L27.2637 5.7442L27.9998 6.14389L28.714 6.58156L29.4044 7.05601L30.0689 7.56594L30.7058 8.10992L31.3135 8.68654L31.8901 9.29413L32.4341 9.93109L32.944 10.5956L33.4184 11.286L33.8561 12.0002L34.2558 12.7363L34.6164 13.4923L34.937 14.2662L35.2166 15.0559L35.4545 15.859L35.65 16.6735L35.8027 17.4971L35.912 18.3275L35.9777 19.1627L35.9997 20L35.9777 20.8373L35.912 21.6725L35.8027 22.5029L35.65 23.3265L35.4545 24.141L35.2166 24.9441L34.937 25.7338L34.6164 26.5077L34.2558 27.2637L33.8561 27.9998L33.4184 28.714L32.944 29.4043L32.4341 30.0689L31.8901 30.7059L31.3135 31.3135L30.7059 31.8901L30.0689 32.4341L29.4043 32.944L28.714 33.4184L27.9998 33.8561L27.2637 34.2558L26.5077 34.6164L25.7338 34.937L24.9441 35.2166L24.141 35.4545L23.3265 35.65L22.5029 35.8027L21.6725 35.912L20.8373 35.9777L20 35.9997L19.1627 35.9777L18.3275 35.912L17.4971 35.8027L16.6735 35.65L15.859 35.4545L15.0559 35.2166L14.2662 34.937L13.4923 34.6164L12.7363 34.2558L12.0002 33.8561L11.286 33.4184L10.5956 32.944L9.93109 32.4341L9.29413 31.8901L8.68654 31.3135L8.10992 30.7058L7.56594 30.0689L7.05601 29.4044L6.58156 28.714L6.14389 27.9998L5.7442 27.2637L5.38359 26.5077L5.06303 25.7338L4.78342 24.9442L4.54552 24.141L4.34997 23.3265L4.19733 22.5029L4.08799 21.6724L4.02227 20.8374L4.00034 20L4.02227 19.1626L4.08799 18.3276L4.19733 17.4971L4.34997 16.6735L4.54552 15.859L4.78342 15.0558L5.06304 14.2662L5.38359 13.4924L5.7442 12.7363L6.14389 12.0002L6.58156 11.286L7.05601 10.5956L7.56593 9.93109L8.10993 9.29414L8.68653 8.68653L9.29414 8.10993L9.93109 7.56593L10.5956 7.05601L11.286 6.58156L12.0002 6.14389L12.7363 5.7442L13.4924 5.38359L14.2662 5.06304L15.0558 4.78342L15.859 4.54552L16.6735 4.34997L17.4971 4.19733L18.3276 4.08799L19.1626 4.02227Z"-->
          <!--                  stroke="#DB9433"-->
          <!--                  stroke-width="2"-->
          <!--                />-->
          <!--                <path-->
          <!--                  d="M17.572 22.117V19.799H20.46C20.8273 19.799 21.163 19.723 21.467 19.571C21.771 19.419 22.0117 19.1973 22.189 18.906C22.3663 18.6147 22.455 18.26 22.455 17.842C22.455 17.4367 22.3663 17.0883 22.189 16.797C22.0117 16.5057 21.771 16.284 21.467 16.132C21.163 15.98 20.8273 15.904 20.46 15.904H17.572V13.586H20.897C21.7457 13.586 22.512 13.757 23.196 14.099C23.88 14.441 24.4183 14.935 24.811 15.581C25.2163 16.2143 25.419 16.968 25.419 17.842C25.419 18.716 25.2163 19.476 24.811 20.122C24.4183 20.7553 23.88 21.2493 23.196 21.604C22.512 21.946 21.7457 22.117 20.897 22.117H17.572ZM15.292 27V13.586H18.275V27H15.292Z"-->
          <!--                  fill="#FFF6DA"-->
          <!--                />-->
          <!--              </svg>-->
          <!--            </span>-->
          <!--            &lt;!&ndash; /.icon &ndash;&gt;-->
          <!--            <h2>Point</h2>-->
          <!--            &lt;!&ndash; /.text &ndash;&gt;-->
          <!--          </div>-->
          <!--          &lt;!&ndash; /.card &ndash;&gt;-->
        </div>
        <!-- /.payment-cards -->
        <div class="payment-total">
          <div class="line">
            <div class="total">{{ $t("common.total") }}</div>
            <!-- /.total -->
            <div class="total-price text-primary">
              {{ order.total ? order.total + " $" : 0 + " $" }}
            </div>
            <!-- /.total-price -->
          </div>
          <!-- /.line -->
          <div class="line">
            <div class="taxes">{{ $t("common.taxes") }}</div>
            <!-- /.total -->
            <div class="taxes-price">
              {{ order.tax ? order.tax + " $" : 0 + " $" }}
            </div>
            <!-- /.total-price -->
          </div>
          <!-- /.line -->
          <div class="line">
            <div class="discount">{{ $t("common.discount") }}</div>
            <!-- /.total -->
            <div class="discount-price">
              {{ order.discount ? order.discount + " $" : 0 + " $" }}
            </div>
            <!-- /.total-price -->
          </div>
          <!-- /.line -->
          <div class="line">
            <div class="remaining">{{ $t("common.remaining_to_rec") }}</div>
            <!-- /.total -->
            <div class="remaining-price text-danger">
              {{ r ? r + " $" : 0 + " $" }}
            </div>
            <!-- /.total-price -->
          </div>
          <!-- /.line -->
        </div>
        <!-- /.payment-total -->
      </div>
      <!-- /.payments -->
      <div class="added-payments">
        <template v-for="(payment, index) of entered_payments">
          <div
            @click="remove_payment(index)"
            :key="index"
            class="box"
            :class="{
              cash: payment.name === 'cash',
              credit: payment.name === 'credit',
            }"
          >
            <h3>{{ payment.name }}</h3>
            <h5 class="amount">{{ payment.amount }}$</h5>
            <!-- /.amount -->
          </div>
        </template>
      </div>
      <!-- /.added-payments -->
    </div>
    <!-- /.wrapper -->
  </div>
  <!-- /.payment -->
</template>

<script>
import section_route from "@/components/section_route";
import payment_keypad from "@/components/keyboards/payment_keypad";
import { clean_order_items } from "@/js/sell_screen/helpers";
import { mapGetters } from "vuex";

export default {
  name: "PaymentView",
  components: {
    section_route,
    payment_keypad,
  },
  computed: {
    ...mapGetters({
      get_order_client: "sell_screen/get_order_client",
    }),
  },
  data() {
    return {
      global: [],
      custom: [],
      s_order: [],
      order: {},
      r: 0, // remaining [ to show the cashier how much is the remaining in the page total and payment keypad ]
      current_payment: {},
      entered_payments: [],
      change: 0,
    };
  },
  methods: {
    get_global_payments() {
      this.$http
        .get(`/payments/get_global_payments/`)
        .then((response) => {
          this.global = response.data.map((payment) => ({
            ...payment,
            type: "global",
          }));
        })
        .catch((e) => {
          console.log(`Error while fetching global payments types ! : ${e}`);
        });
    },
    get_custom_payments() {
      this.$http
        .get(`/payments/get_payments/`)
        .then((response) => {
          this.custom = response.data.map((payment) => ({
            ...payment,
            type: "custom",
          }));
        })
        .catch((e) => {
          console.log(`Error while fetching custom payments types ! : ${e}`);
        });
    },
    submit_order() {
      for (let order of this.order.items) {
        this.s_order.push({
          product_id: order.id,
          quantity: order.ordered_quantity,
          price_with_tax: order.price_with_tax,
          discount: 0,
          discount_in_percentage: 0,
        });
      }
      this.$http
        .post("/sell_screen/create_new_order", {
          client_id: this.get_order_client ? this.get_order_client.id : null,

          discount: this.order.cart_fixed_discount,
          discount_in_percentage: this.order.cart_discount_percentage,

          items: clean_order_items(this.order.items),
          payments: this.entered_payments,
        })
        .then(() => {
          this.$store.dispatch("saved_orders/delete_current_order");
          this.$router.push({ name: "sell_screen" });
        })
        .catch(() => {
          this.$store.dispatch("utils/push_alert", {
            message: `Failed to create the order`,
            type: "danger",
          });
          console.log("Error while submit");
        });
    },
    payment_method(payment) {
      if (
        payment.type === "custom" &&
        payment.customer_required &&
        !this.get_order_client
      ) {
        this.$store.dispatch("utils/push_alert", {
          message: this.$t("sell_screen.payment_require_customer_error"),
          type: "danger",
        });
        return;
      }
      this.current_payment = payment;
      document.getElementById("payment-modal-btn").click();
    },
    remove_payment(index) {
      this.entered_payments.splice(index, 1);
      this.calc_remaining();
    },
    calc_remaining() {
      let sum = 0;
      let array = this.entered_payments.map((payment) => {
        return payment.amount;
      });
      array.forEach((item) => {
        sum += parseInt(item);
      });
      if (this.order.total - sum.toFixed(2) < 0) {
        this.change = -1 * (this.order.total - sum.toFixed(2));
        this.amount_alert();
        // this.entered_payments.pop();
        // this.calc_remaining(); // weird goes to the bottom of the function and then recurse that's why im using else
      } else {
        this.r = this.order.total - sum.toFixed(2);
        if (parseInt(this.r) === 0) {
          this.submit_order();
        }
      }
    },
    amount_alert() {
      this.$refs.change_alert.click();
    },
  },
  created() {
    this.get_global_payments();
    this.get_custom_payments();
    this.order = this.$store.getters["sell_screen/get_current_order"];
    this.r = this.order.total;
  },
  mounted() {
    // setting active input from payment_keypad component
    const input = document.getElementById("r-input");
    this.$store.dispatch("keyboard/set_active_input", input);
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/home/payment";
.modal {
  //background: $secondary-background-color;
  //--bs-modal-width: 80%;
  --bs-modal-width: 668px;
  height: 80%;

  padding: 1rem;
  .modal-content {
    //background: $main-white;
  }
  .modal-dialog {
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
  }
}
</style>
