export default {
  UserIcon: require("@/assets/user-286.svg"),
  HomeIcon: require("@/assets/svgs/HomeIcon.svg"),
  HistoryIcon: require("@/assets/svgs/HistoryIcon.svg"),
  SettingIcon: require("@/assets/svgs/SettingIcon.svg"),
  FilterOnIcon: require("@/assets/svgs/FilterOnIcon.svg"),
  FilterOffIcon: require("@/assets/svgs/FilterOffIcon.svg"),

  // SellScreen
  PromoItemIcon: require("@/assets/svgs/sell_screen/PromoItemIcon.svg"),
  TrashIcon: require("@/assets/svgs/sell_screen/TrashIcon.svg"),
  DiscountIcon: require("@/assets/svgs/sell_screen/DiscountIcon.svg"),
  InvoicesIcon: require("@/assets/svgs/sell_screen/InvoicesIcon.svg"),
  CustomerIcon: require("@/assets/svgs/sell_screen/CustomerIcon.svg"),
  VoidOrderIcon: require("@/assets/svgs/sell_screen/VoidOrderIcon.svg"),

  // Dashboard
  CashIcon: require("@/assets/svgs/dashboard/cash.svg"),
  RefundIcon: require("@/assets/svgs/dashboard/refund.svg"),
  InventoryCountIcon: require("@/assets/svgs/dashboard/InventoryCount.svg"),
  ArrowRightFullIcon: require("@/assets/svgs/dashboard/ArrowRightFullIcon.svg"),
  ArrowLeftFullIcon: require("@/assets/svgs/dashboard/ArrowLeftFullIcon.svg"),

  // auth
  EyeOnIcon: require("@/assets/svgs/auth/EyeOnIcon.svg"),
  EyeOffIcon: require("@/assets/svgs/auth/EyeOffIcon.svg"),

  // Inside Settings
  ProductIcon: require("@/assets/svgs/products/ProductIcon.svg"),
  AllProductsIcon: require("@/assets/svgs/products/AllProductsIcon.svg"),
  ItemBoxIcon: require("@/assets/svgs/products/ItemBoxIcon.svg"),
  PriceTagsIcon: require("@/assets/svgs/products/PriceTagsIcon.svg"),
  AddonsIcon: require("@/assets/svgs/products/AddonsIcon.svg"),

  DataReportIcon: require("@/assets/svgs/DataReportIcon.svg"),
  DashboardIcon: require("@/assets/svgs/DashboardIcon.svg"),
  SecurityIcon: require("@/assets/svgs/SecurityIcon.svg"),
  InventoryIcon: require("@/assets/svgs/InventoryIcon.svg"),
  SettingsIcon: require("@/assets/svgs/SettingsIcon.svg"),
  DocumentsIcon: require("@/assets/svgs/DocumentsIcon.svg"),
  PurchaseIcon: require("@/assets/svgs/PurchaseIcon.svg"),
  PromoIcon: require("@/assets/svgs/PromoIcon.svg"),
  BranchesIcon: require("@/assets/svgs/BranchesIcon.svg"),
  CompanyIcon: require("@/assets/svgs/CompanyIcon.svg"),
  ClientsIcon: require("@/assets/svgs/ClientsIcon.svg"),

  LogoutIcon: require("@/assets/svgs/LogoutIcon.svg"),
  // arrows
  CollapseIcon: require("@/assets/svgs/CollapseIcon.svg"),
  ArrowRightIcon: require("@/assets/svgs/ArrowRightIcon.svg"),
  ArrowLeftIcon: require("@/assets/svgs/ArrowLeftIcon.svg"),
  // add, view, edit, delete
  add: require("@/assets/svgs/control/add.svg"),
  view: require("@/assets/svgs/control/view.svg"),
  edit: require("@/assets/svgs/control/edit.svg"),
  delete: require("@/assets/svgs/control/delete.svg"),
  // print
  print: require("@/assets/svgs/print.svg"),

  // zatca
  ZatcaIcon: require("@/assets/svgs/zatca/zatca.svg"),
};
