import store from "@/store/index";
import { dev_log } from "@/js/utils";

function prevent_negative(vm, item, stored_qty, increased_qty) {
  // if prevent negative is enabled
  if (
    vm.settings.prevent_negative &&
    (stored_qty > item.quantity || increased_qty > item.quantity)
  ) {
    vm.$store.dispatch("utils/push_alert", {
      message: `You cannot sell product quantity less than 0 check negative option in the settings`,
      type: "danger",
    });
    return true;
  }
  return false;
}

export function show_itembox_modal(vm, order_item) {
  // convert clicked_itembox_product itemboxes passed as props to itembox_modal component
  vm.clicked_itembox_product = order_item;
  // open the itembox_modal window
  vm.$refs.show_itembox_modal_btn.click();
}

export function add_item(vm, order_item) {
  const stored_qty = store.getters["keyboard/get_current_qty"];

  // change counter of ordered_quantity property
  if (stored_qty > 0) {
    order_item.ordered_quantity = stored_qty;
  }

  if (
    prevent_negative(vm, order_item, stored_qty, order_item.ordered_quantity)
  ) {
    return;
  }

  store
    .dispatch("sell_screen/add_order_item", order_item)
    .then(() => {
      dev_log(
        "adding new order_item invoice list | invoice_list.js => add_item()",
        order_item
      );
    })
    .catch((error) => {
      dev_log("error while trying to add new item to invoice list ", error);
    });
  // vm.check_reorder_qty(order_item); todo: fix
  store.dispatch("keyboard/reset_quantity");
}
