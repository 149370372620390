import store from "@/store";
import { Order } from "@/js/sell_screen/order/order";
import { dev_log } from "@/js/utils";
import { round_decimal } from "@/helpers";
import { PromoItem } from "@/js/sell_screen/promo/promo";
import { copy_order_item } from "@/js/sell_screen/helpers";

class Discount {
  constructor(
    discount_value = 0,
    is_fixed_discount = false,
    is_discount_for_total = true,
    total_or_subtotal = 0
  ) {
    // user inputted discount
    this.discount_value = discount_value;
    this.is_fixed_discount = is_fixed_discount;
    this.is_discount_for_total = is_discount_for_total;
    this.total_or_subtotal = total_or_subtotal;
  }

  get discount_amount() {
    /* Return Fixed Discount Amount
     and convert it to fixed if it's Percentage */
    return this.is_fixed_discount
      ? this.discount_value
      : (this.total_or_subtotal * this.discount_value) / 100;
  }
}

class DistributeOrderDiscount {
  #order_item = null;
  // discount instance of Discount class
  #discount = null;

  #new_subtotal = 0;
  #new_taxes = 0;
  #new_total = 0;

  /* Distributing Order Discount On item check if it is valid*/
  constructor(order_item_instance, discount) {
    this.#order_item = order_item_instance;
    this.#discount = discount;
    this.run_discount();
  }

  //todo:  should be private method solve "private methods are not enabled. Please add `@babel/plugin-proposal-private-methods` to your configuration."
  run_discount() {
    let subtotal = 0;
    let taxes = 0;
    let total = 0;

    const discount_ratio =
      this.#discount.discount_amount / this.#discount.total_or_subtotal;

    // Apply the discount proportionally to the item's subtotal
    subtotal = this.#order_item.subtotal * (1 - discount_ratio);

    // Adjust taxes proportionally by reducing them according to the discount ratio
    // The discount ratio reduces the taxable amount, so taxes should be reduced similarly
    taxes = this.#order_item.tax_total * (1 - discount_ratio);

    // Calculate the total as the discounted subtotal + discounted taxes
    total = subtotal + taxes;

    // Apply the discount ratio to each addon
    for (let addon of this.#order_item.addons) {
      let addon_subtotal = 0;
      let addon_taxes = 0;
      let addon_total = 0;

      // Apply the discount proportionally to the item's subtotal
      addon_subtotal = addon.chosen_type.subtotal * (1 - discount_ratio);

      // Adjust taxes proportionally by reducing them according to the discount ratio
      // The discount ratio reduces the taxable amount, so taxes should be reduced similarly
      addon_taxes = addon.chosen_type.taxes * (1 - discount_ratio);

      // Calculate the total as the discounted subtotal + discounted taxes
      addon_total = addon_subtotal + addon_taxes;

      addon.chosen_type.subtotal = round_decimal(addon_subtotal);
      addon.chosen_type.taxes = round_decimal(addon_taxes);
      addon.chosen_type.price = round_decimal(
        addon_total / addon.chosen_type.qty
      );
      addon.chosen_type.total = round_decimal(
        addon.chosen_type.price * addon.chosen_type.qty
      );
    }
    // recalculate the addons after applying discount on them
    this.#order_item.calculate_addons();

    this.#new_subtotal = subtotal;
    this.#new_taxes = taxes;
    this.#new_total = total;
  }
  get valid_discount() {
    if (this.#order_item.tax_total > 0 && this.#new_taxes < 0) return false;
    return this.#new_subtotal > 0 && this.#new_total > 0;
  }
  get values_after_discount() {
    dev_log(
      {
        subtotal: this.#new_subtotal,
        taxes: this.#new_taxes,
        total: this.#new_total,
      },
      "DistributeOrderDiscount -> values_after_discount()"
    );
    return {
      subtotal: this.#new_subtotal,
      taxes: this.#new_taxes,
      total: this.#new_total,
    };
  }
}

class OrderItemDiscount {
  #order_item = null;
  #order_item_total_or_subtotal = 0;

  #new_subtotal = 0;
  #new_taxes = 0;
  #new_total = 0;

  /* Distributing Order Discount On item check if it is valid*/
  constructor(order_item_instance, order_item_total_or_subtotal) {
    this.#order_item = order_item_instance;
    this.#order_item_total_or_subtotal = order_item_total_or_subtotal;
    this.run_discount();
  }

  //todo:  should be private method solve "private methods are not enabled. Please add `@babel/plugin-proposal-private-methods` to your configuration."
  run_discount() {
    let subtotal = 0;
    let taxes = 0;
    let total = 0;

    const discount_ratio =
      this.#order_item.total_discount / this.#order_item_total_or_subtotal;

    // Apply the discount proportionally to the item's subtotal
    subtotal = this.#order_item.subtotal * (1 - discount_ratio);

    // Adjust taxes proportionally by reducing them according to the discount ratio
    // The discount ratio reduces the taxable amount, so taxes should be reduced similarly
    taxes = this.#order_item.tax_total * (1 - discount_ratio);

    // Calculate the total as the discounted subtotal + discounted taxes
    total = subtotal + taxes;

    // Apply the discount ratio to each addon
    for (let addon of this.#order_item.addons) {
      let addon_subtotal = 0;
      let addon_taxes = 0;
      let addon_total = 0;

      // Apply the discount proportionally to the item's subtotal
      addon_subtotal = addon.chosen_type.subtotal * (1 - discount_ratio);

      // Adjust taxes proportionally by reducing them according to the discount ratio
      // The discount ratio reduces the taxable amount, so taxes should be reduced similarly
      addon_taxes = addon.chosen_type.taxes * (1 - discount_ratio);

      // Calculate the total as the discounted subtotal + discounted taxes
      addon_total = addon_subtotal + addon_taxes;

      addon.chosen_type.subtotal = round_decimal(addon_subtotal);
      addon.chosen_type.taxes = round_decimal(addon_taxes);
      addon.chosen_type.price = round_decimal(
        addon_total / addon.chosen_type.qty
      );
      addon.chosen_type.total = round_decimal(
        addon.chosen_type.price * addon.chosen_type.qty
      );
    }

    this.#new_subtotal = subtotal;
    this.#new_taxes = taxes;
    this.#new_total = total;
  }
  get valid_discount() {
    if (this.#order_item.tax_total > 0 && this.#new_taxes < 0) return false;
    return this.#new_subtotal > 0 && this.#new_total > 0;
    // return this.#new_subtotal > 0 && this.#new_taxes > 0 && this.#new_total > 0;
  }
  get values_after_discount() {
    return {
      subtotal: this.#new_subtotal,
      taxes: this.#new_taxes,
      total: this.#new_total,
    };
  }
}

class OrderDiscount extends Order {
  constructor(
    client,
    datetime,
    items = [],

    cart_fixed_discount,
    cart_discount_percentage,
    total_discount
  ) {
    super(
      client,
      datetime,
      items,
      cart_fixed_discount,
      cart_discount_percentage
    );

    this.items = [];

    // discount
    this.discount_after =
      store.getters["general_settings/get_settings"].discount_type ===
      "after_tax";

    this.cart_fixed_discount = cart_fixed_discount;
    this.cart_discount_percentage = cart_discount_percentage;
    this.total_discount = total_discount || 0;
    this.discount = null;

    this.copy_order_items(items);
    this.handle_promo();
    this.handle_discount();
  }

  get is_order_discount() {
    return this.cart_fixed_discount > 0 || this.cart_discount_percentage > 0;
  }

  copy_order_items(_items) {
    // copy order_items into a new array
    this.items = _items.map((item) => {
      return copy_order_item(item);
    });
  }

  duplicate_order_item(item, range) {
    let items = [];
    for (let i = 0; i < range; i++) {
      items.push(copy_order_item(item));
    }
    return items;
  }

  extend_promo_items() {
    /*
     loop through items and extend order_items quantity if there is a promo in single order_item with quantity
    */

    // creating a new array instead of directly modifying the original items array to prevent an infinite loop
    let extended_items = [];
    for (let i = 0; i < this.items.length; i++) {
      let order_item = this.items[i];
      extended_items.push(order_item);
      if (order_item.promo) {
        if (order_item.ordered_quantity > 1) {
          const duplicate_range = order_item.ordered_quantity - 1;
          order_item.ordered_quantity = 1;
          const cloned_items = this.duplicate_order_item(
            order_item,
            duplicate_range
          );
          extended_items.splice(i, 0, ...cloned_items);
        }
      }
    }
    return extended_items;
  }

  handle_promo() {
    this.items = this.extend_promo_items();

    for (let i = 0; i < this.items.length; i++) {
      let order_item = this.items[i];

      if (order_item.promo) {
        const promo_item = new PromoItem(order_item);
        const { apply_to_all, apply_to_next } = order_item.promo;

        if (promo_item.is_conditional) {
          if (promo_item.is_all) {
            /** conditional_apply_to_all **/

            // Check if the total number of this item (including promo) exceeds `apply_to_all`
            const repeated_order_item = this.items.filter(
              (p_item) => p_item.id === promo_item.id
            ).length;

            if (repeated_order_item < promo_item.apply_to_all) {
              // Still within `apply_to_all` limit, skip promo
              console.log("Promo not applied yet: within apply_to_all limit");
            } else {
              // Exceeds `apply_to_all`, apply promo
              const update_promo_item = new PromoItem(promo_item);
              update_promo_item.apply_promo_on_price();
              this.items[i] = update_promo_item;
            }
          } else {
            /** conditional_apply_to_next **/
            /*
             * i.e.
             * apply_to_all = 3
             * apply_to_next = 2
             * For every `apply_to_all` items, add `apply_to_next` promo items one at a time.
             */
            let handled_promo_items = [];
            let handled_promo_items_ids = handled_promo_items.map((item) => {
              return item.id;
            });
            if (!handled_promo_items_ids.includes(order_item.id)) {
              handled_promo_items.push(order_item);
              let promo_item_indexes = [];
              for (
                let item_index = 0;
                item_index < this.items.length;
                item_index++
              ) {
                if (order_item.id === this.items[item_index].id) {
                  promo_item_indexes.push(item_index);
                }
              }

              let apply_to_all_count = 0;
              let apply_to_next_count = 0;
              for (let promo_item_index of promo_item_indexes) {
                if (apply_to_all_count < apply_to_all) {
                  apply_to_all_count += 1;
                } else {
                  if (apply_to_next_count < apply_to_next) {
                    apply_to_next_count += 1;
                    promo_item.apply_promo_on_price();

                    const new_order_item = copy_order_item(order_item);

                    new_order_item.subtotal = promo_item.subtotal;
                    new_order_item.tax_total = promo_item.tax_total;
                    new_order_item.price_with_tax = promo_item.price_with_tax;
                    new_order_item.is_promo = true;
                    this.items[promo_item_index] = new_order_item;
                  } else {
                    apply_to_all_count = 1;
                    apply_to_next_count = 0;
                  }
                }
              }
            }
          }
        } else {
          // Not conditional promo logic
          promo_item.not_conditional();
          const new_order_item = copy_order_item(order_item);
          new_order_item.subtotal = promo_item.subtotal;
          new_order_item.tax_total = promo_item.tax_total;
          new_order_item.price_with_tax = promo_item.price_with_tax;
          new_order_item.is_promo = true;

          this.items[i] = new_order_item;
        }
      }
    }
  }

  handle_discount() {
    /* NOTE: the order is matter if its on items first or the cart first */

    // 1- Discount On Items First
    for (let item of this.items) {
      if (!item.is_item_discount) {
        continue;
      }
      if (item.is_item_discount) {
        item.set_discount();
        this.add_order_item_discount(item);
        item.calculate_addons();
      }
    }

    // 2- Discount On Cart then
    if (this.is_order_discount) {
      this.set_discount();
      console.log("order_total: ", this.total);
      for (let item of this.items) {
        this.distribute_order_discount(item);
      }
    }
  }

  add_order_item_discount(order_item_instance) {
    const order_item_total_or_subtotal = this.discount_after
      ? order_item_instance.price_with_tax + order_item_instance.addons_total
      : order_item_instance.subtotal + order_item_instance.addons_subtotal;

    console.log("item: ", order_item_total_or_subtotal);

    const order_item_discount = new OrderItemDiscount(
      order_item_instance,
      order_item_total_or_subtotal
    );

    if (order_item_discount.valid_discount) {
      const { subtotal, taxes, total } =
        order_item_discount.values_after_discount;
      order_item_instance.subtotal = subtotal;
      order_item_instance.tax_total = taxes;
      order_item_instance.price_with_tax = total;
    } else {
      store
        .dispatch("utils/push_alert", {
          message: `Invalid Order Item Discount`,
          type: "danger",
        })
        .then(() => {
          dev_log(
            "Invalid Order Item Discount",
            "OrderDiscount -> add_order_item_discount() -> else block"
          );
        });
      this.reset_order_item_discount(order_item_instance);
    }
  }

  distribute_order_discount(order_item_instance) {
    /* distributing order discount on order items */

    const order_discount = new DistributeOrderDiscount(
      order_item_instance,
      this.discount
    );
    if (order_discount.valid_discount) {
      const { subtotal, taxes, total } = order_discount.values_after_discount;
      order_item_instance.subtotal = subtotal;
      order_item_instance.tax_total = taxes;
      order_item_instance.price_with_tax = total;
    } else {
      store
        .dispatch("utils/push_alert", {
          message: `Invalid Order Discount`,
          type: "danger",
        })
        .then(() => {
          dev_log(
            "Invalid Order Discount",
            "OrderDiscount -> distribute_order_discount() -> else block"
          );
        });
      this.reset_discount();
    }
  }

  set_discount() {
    /* Setting Discount Value based on discount after/before
     * percentage or fixed
     * */
    const discount_value =
      this.cart_fixed_discount || this.cart_discount_percentage;

    const is_fixed_discount =
      this.cart_fixed_discount > 0 && this.cart_discount_percentage <= 0;

    const is_discount_for_total = this.discount_after;

    const total_or_subtotal = is_discount_for_total
      ? this.total
      : this.subtotal;

    this.discount = new Discount(
      discount_value,
      is_fixed_discount,
      is_discount_for_total,
      total_or_subtotal
    );
    this.total_discount = this.discount.discount_amount;
  }

  reset_discount() {
    this.total_discount = 0;
    store.dispatch("sell_screen/reset_order_discount").then(() => {
      dev_log(
        "resetting invalid order discount",
        "OrderDiscount -> reset_discount()"
      );
    });
  }
  reset_order_item_discount(order_item_instance) {
    order_item_instance.reset_discount();
    store.dispatch("sell_screen/reset_order_item_discount").then(() => {
      dev_log(
        "resetting invalid OrderItem discount",
        "OrderDiscount -> reset_order_item_discount()"
      );
    });
  }
}

export { OrderDiscount };
