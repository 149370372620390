<template>
  <div class="purchase">
    <!-- PURCHASE MODAL -->
    <purchase_modal
      :modal_id="'new-modal'"
      :selected_product="selected_product"
    >
      <template #inside-modal-body>
        <div class="row flex-md-column gap-3">
          <div class="row justify-content-between">
            <span class="col-md-2">{{ $t("common.quantity") }}</span>
            <div class="col-md-9">
              <input
                ref="qty"
                @keyup.enter="add_product"
                @input="count_product_modal"
                id="qty"
                type="text"
                pattern="[0-9]+(\.[0-9]+)?"
                class="form-control"
                style="padding: 0.6rem"
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <span class="col-md-2">{{ $t("common.cost_before_tax") }}</span>
            <div class="col-md-9">
              <input
                ref="current_product_subtotal_input"
                :value="selected_product.last_cost"
                @input="count_product_modal"
                id="current_product_subtotal_input"
                type="text"
                pattern="[0-9]+(\.[0-9]+)?"
                class="form-control"
                style="padding: 0.6rem"
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <span class="col-md-2">{{ $t("common.cost_after_tax") }}</span>
            <div class="col-md-9">
              <input
                ref="current_product_total_input"
                @input="count_product_modal"
                :value="selected_product.cost"
                id="current_product_total_input"
                type="text"
                pattern="[0-9]+(\.[0-9]+)?"
                class="form-control"
                style="padding: 0.6rem"
              />
            </div>
          </div>
          <div class="row justify-content-between" v-if="selected_product">
            <span class="col-md-2">{{ $t("common.tax") }}</span>
            <div class="col-md-9">
              <select
                ref="selected_tax"
                @change="count_product_modal"
                class="form-select"
              >
                <option value="0" selected>
                  {{ $t("common.chose_a_tax") }}
                </option>
                <!-- todo: handle select on multiple taxes -->
                <template
                  v-if="selected_product.tax && selected_product.tax.length > 0"
                >
                  <option
                    v-for="tax in taxes"
                    v-bind:id="tax.id"
                    :value="tax.percentage_value"
                    :key="tax.id"
                    :selected="
                      selected_product.tax
                        ? tax.id === selected_product.tax[0].id
                        : false
                    "
                  >
                    {{ tax.name }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="tax in taxes"
                    v-bind:id="tax.id"
                    :value="tax.percentage_value"
                    :key="tax.id"
                  >
                    {{ tax.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="row justify-content-between">
            <span class="col-md-2">{{ $t("common.discount") }}</span>
            <div
              class="form-group row justify-content-start align-items-center col-md-9"
            >
              <div class="col-md-5">
                <select
                  ref="selected_discount_type"
                  @change="set_discount_placeholder"
                  class="form-select"
                >
                  <option selected value="f">{{ $t("common.fixed") }}</option>
                  <option value="p">{{ $t("common.percentage") }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <input
                  ref="product_discount_input"
                  @input="count_product_modal"
                  :placeholder="discount_type"
                  type="text"
                  pattern="[0-9]+(\.[0-9]+)?"
                  class="form-control discount-input"
                />
              </div>
            </div>
          </div>
          <hr />
          <!-- TOTAL -->
          <div class="row justify-content-between">
            <span class="col-md-2">
              {{ $t("common.total_cost_before_tax") }}</span
            >
            <div class="col-md-9">
              <input
                ref="current_product_counted_subtotal"
                type="number"
                class="form-control"
                disabled
              />
            </div>
          </div>

          <div class="row justify-content-between">
            <span class="col-md-2">{{
              $t("common.total_cost_after_tax")
            }}</span>
            <div class="col-md-9">
              <input
                ref="current_product_counted_total"
                type="number"
                class="form-control"
                disabled
              />
            </div>
          </div>
        </div>
      </template>
      <template #keypad>
        <div class="d-flex justify-content-end mt-3">
          <button
            v-if="current_action === 'add'"
            @click="add_product"
            class="pos-primary-btn px-5 py-3"
          >
            {{ $t("common.add") }}
          </button>
          <button v-else @click="add_product" class="pos-primary-btn px-5 py-3">
            {{ $t("common.edit") }}
          </button>
        </div>
      </template>
    </purchase_modal>
    <button
      @click="focus_input('#qty')"
      id="add-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#new-modal"
    ></button>
    <!-- END OF PURCHASE MODAL -->

    <!-- ConfirmDelete Modal -->
    <div
      class="modal fade"
      id="delmodal"
      tabindex="-1"
      aria-labelledby="delmodalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            id="close-del-modal-btn"
            type="button"
            class="btn-close d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="modal-body">
            <span class="text-danger" style="font-size: 1.5rem">
              {{ $t("common.delete_confirm") }}
              <span class="text-success text-decoration-underline">
                {{ get_selected_product.name }}
              </span>
              ?
            </span>
          </div>
          <div class="modal-footer">
            <button @click="delete_item" class="btn btn-danger px-4">
              {{ $t("common.delete") }}
            </button>
            <button @click="close_current_modal" class="btn btn-secondary px-5">
              {{ $t("common.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      id="del-modal-btn"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#delmodal"
    ></button>
    <!-- End of ConfirmDelete Modal -->

    <HeaderSection
      ref="header_section"
      :page_type="{
        new_page,
        refund_page,
        purchases_update,
        refund_update,
      }"
      :searched_products="searched_products"
      :set_item="set_item"
    ></HeaderSection>

    <MiddleSection ref="middle_section" :products="products"></MiddleSection>
    <FooterSection
      ref="footer_section"
      :discount_type="discount_type"
      :products="products"
      :updated_products="updated_products"
      :count_items="count_items"
    ></FooterSection>
  </div>
</template>

<script>
import purchase_modal from "@/components/settings/purchases/purchase_modal";
import HeaderSection from "@/components/settings/purchases/HeaderSection";
import MiddleSection from "@/components/settings/purchases/MiddleSection";
import FooterSection from "@/components/settings/purchases/FooterSection";
import { close_current_modal, fixed_float, focus_input } from "@/helpers";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PurchaseCrud",
  components: {
    HeaderSection,
    MiddleSection,
    FooterSection,
    purchase_modal,
  },
  computed: {
    ...mapGetters({
      get_selected_product: "purchase_crud/get_selected_product",
      get_invoice_discount: "purchase_crud/get_invoice_discount",
    }),
    invoice_discount: {
      get() {
        return this.get_invoice_discount;
      },
      set(val) {
        this.set_invoice_discount(val);
      },
    },
    selected_product: {
      get() {
        return this.get_selected_product;
      },
      set(product) {
        return this.set_selected_product(product);
      },
    },

    counted_subtotal: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_subtotal"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_subtotal", val);
      },
    },
    counted_tax: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_tax"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_tax", val);
      },
    },
    counted_total: {
      get() {
        return this.$store.getters["purchase_crud/get_counted_total"];
      },
      set(val) {
        this.$store.dispatch("purchase_crud/set_counted_total", val);
      },
    },
    new_page() {
      return this.$route.name === "purchases_new";
    },
    refund_page() {
      return this.$route.name === "purchases_new_refund";
    },
    purchases_update() {
      return this.$route.name === "purchases_update";
    },
    refund_update() {
      return this.$route.name === "purchases_refund_update";
    },
  },
  data() {
    return {
      ...mapActions({
        set_selected_product: "purchase_crud/set_selected_product",
        set_invoice_discount: "purchase_crud/set_invoice_discount",
      }),
      products: [],
      // product that user made changes of
      updated_products: [],

      info: {}, // in case if its update page to bind elements
      taxes: [],
      selected_tax: {}, // from dropdown modal
      current_discount: 0,
      // search_products component variables
      cached_products: [],
      searched_products: [], // to render the searched products

      // discount placeholder
      discount_type: "$",

      // actions to guide modal for add or update
      current_action: "add",

      // backend_records_actions
      actions: { create: 1, update: 2, delete: 3 },
    };
  },
  watch: {
    async products() {
      await this.$nextTick(); // Ensure data is available in the next tic
      this.$refs.footer_section.total_discount_changes();
    },
  },
  methods: {
    close_current_modal: close_current_modal,
    focus_input: focus_input,
    qty_changes() {
      // calculate total disabled inputs based on qty
      const qty = this.$refs.qty;
      const subtotal = this.$refs.current_product_counted_subtotal;
      const total = this.$refs.current_product_counted_total; // with taxes
      subtotal.value = (
        parseInt(qty.value || 0) *
        parseFloat(this.$refs.current_product_subtotal_input.value || 0)
      ).toFixed(2);
      total.value = (
        parseInt(qty.value || 0) *
        parseFloat(this.$refs.current_product_total_input.value || 0)
      ).toFixed(2);
    },
    subtotal_changes() {
      // calculate other inputs based on subtotal value
      const subtotal = this.$refs.current_product_subtotal_input; // without tax
      const price = this.$refs.current_product_total_input; // with tax
      // todo: handle this on multiple taxes
      const tax = this.$refs.selected_tax.value;
      price.value =
        parseFloat(subtotal.value || 0) +
        parseFloat(subtotal.value || 0) * (parseFloat(tax) / 100);
      price.value = parseFloat(price.value).toFixed(2);
    },
    price_changes() {
      // calculate other inputs based on price value
      const subtotal = this.$refs.current_product_subtotal_input; // without tax
      const price = this.$refs.current_product_total_input; // with tax
      // todo: handle this on multiple taxes
      const tax = this.$refs.selected_tax.value;
      const tax_ex = parseFloat(tax) / 100;
      subtotal.value = (parseFloat(price.value) / (tax_ex + 1)).toFixed(2);
    },
    product_discount_changes() {
      const tax = this.$refs.selected_tax.value;

      // const qty = this.$refs.qty.value;

      let current_product_counted_subtotal =
        this.$refs.current_product_counted_subtotal;

      let current_product_counted_total =
        this.$refs.current_product_counted_total; // with taxes

      this.current_discount = this.$refs.product_discount_input.value;
      // const tax_inc = (parseFloat(tax) / 100 + 1).toFixed(2);
      let subtotal_with_qty = 0;
      let taxes = 0;

      if (this.discount_type === "%") {
        // (percent * whole) / decimal.Decimal(100)

        this.current_discount = parseFloat(
          (
            parseFloat(this.$refs.product_discount_input.value || 0) *
            parseFloat(
              this.$refs.current_product_counted_subtotal.value || 0
            ).toFixed(2)
          ).toFixed(2) / 100
        );
      }

      subtotal_with_qty =
        parseFloat(current_product_counted_subtotal.value) -
        this.current_discount;

      taxes = subtotal_with_qty * (parseFloat(tax) / 100);
      console.log("taxes after discount ", taxes);

      current_product_counted_subtotal.value = fixed_float(subtotal_with_qty);

      current_product_counted_total.value =
        fixed_float(
          taxes + parseFloat(current_product_counted_subtotal.value)
        ) || 0;
    },
    check_valid_modal() {
      if (parseInt(this.$refs.qty.value) <= 0 || this.$refs.qty.value === "") {
        this.$refs.qty.style.border = "1px solid red";
        return false;
      } else if (
        parseInt(this.$refs.current_product_counted_total.value) <= 0
      ) {
        this.$refs.product_discount_input.style.border = "1px solid red";
        return false;
      }
      return true;
    },
    set_item(product) {
      /*
       * used to set product when user search for products

       */

      document.getElementById("add-modal-btn").click();
      this.$refs.header_section.$refs.search_component.search_val = "";
      this.selected_product = product;

      this.qty_changes();
    },
    add_backend_action(record_type, item) {
      // check if item is already exists , remove it if it does
      const index = this.updated_products.findIndex(
        (obj) => obj.id === item.id
      );
      if (index) {
        this.updated_products.splice(index, 1);
      }

      switch (record_type) {
        case this.actions.create:
          this.updated_products.push({ ...item, action: this.actions.create });
          break;
        case this.actions.update:
          this.updated_products.push({ ...item, action: this.actions.update });
          break;
        case this.actions.delete:
          this.updated_products.push({ ...item, action: this.actions.delete });
          break;
      }
    },
    add_product() {
      this.current_action = "add";

      if (this.check_valid_modal()) {
        this.selected_product.quantity = parseInt(this.$refs.qty.value);
        this.selected_product.subtotal =
          this.$refs.current_product_subtotal_input.value;
        this.selected_product.price_with_tax =
          this.$refs.current_product_total_input.value;
        this.selected_product.discount = this.current_discount || 0;

        this.selected_product.subtotal = parseFloat(
          this.$refs.current_product_counted_subtotal.value
        );

        this.selected_product.total = parseFloat(
          this.$refs.current_product_counted_total.value
        );

        this.selected_product.tax_total = (
          this.selected_product.total - this.selected_product.subtotal
        ).toFixed(2);

        // todo: handle on multiple taxes
        const selected_tax_id =
          this.$refs.selected_tax[this.$refs.selected_tax.selectedIndex].id;

        const selected_tax = [
          this.taxes.find((tax) => tax.id === parseInt(selected_tax_id)),
        ];

        try {
          if (selected_tax[0].id) {
            this.selected_product.tax = selected_tax;
          }
        } catch {
          this.selected_product.tax = [];
        }

        const index = this.products.findIndex(
          (obj) =>
            obj.id === this.selected_product.item_id &&
            obj.item_type === this.selected_product.item_type
        );
        const product = this.products[index];

        // add item if it doesn't exist in the table or replace it if exists
        if (product) {
          this.products.splice(index, 1, this.selected_product);
        } else {
          this.products.push(this.selected_product);
        }

        // check if item have purchase_item_id if so it means it is updating
        if (this.selected_product.item_id) {
          this.add_backend_action(this.actions.update, this.selected_product);
        } else {
          this.add_backend_action(this.actions.create, this.selected_product);
        }

        this.count_items();

        close_current_modal();
        this.searched_products = [];
        this.$refs.qty.value = "";

        this.current_discount = "";
        this.$refs.product_discount_input.value = "";

        this.$refs.current_product_counted_subtotal.value = "";
        this.$refs.current_product_counted_total.value = "";
      }
    },
    update_product() {
      /*
       * used to check if user updating the product to bind modified properties
       * modified properties is  <quantity, discount>
       * <quantity> : to prevent getting stock quantity,
       *              function will get product quantity from table or "" if the product is added for the first time
       * <discount> : db object doesn't have a discount property so its added here for the first time
       *              on update u need to get it from the table or set it "" for the first time product is added
       */
      console.log("updating product: ", this.get_selected_product);
      const product = this.get_selected_product;
      document.getElementById("add-modal-btn").click();

      if (this.current_action === "update") {
        this.$refs.qty.value = product.quantity;
        this.$refs.product_discount_input.value = product.discount || 0;
        this.$refs.current_product_total_input.value = product.cost || 0;
      }

      this.qty_changes();
      this.product_discount_changes();
    },
    delete_item() {
      close_current_modal();
      let index = this.products.findIndex(
        (e) => e.id === this.selected_product.id
      );
      const product = this.products[index];

      // add purchase_item id to deleted it from the backend
      if (product && product.purchase) {
        this.add_backend_action(this.actions.delete, this.selected_product);
      }
      this.products.splice(index, 1);
      // in case if deleted item added again prevent auto select
      this.$store.dispatch("purchase_crud/clear_selected_product");
      this.count_items();
    },
    reset_counted_items() {
      this.counted_subtotal = 0;
      this.counted_tax = 0;
      this.counted_total = 0;
    },
    count_product_modal(e) {
      const changes = e.target.id;
      if (changes === "current_product_subtotal_input") {
        this.subtotal_changes();
      } else if (changes === "current_product_total_input") {
        this.price_changes();
      } else {
        // when tax selected
        this.subtotal_changes();
      }
      this.qty_changes();
      this.product_discount_changes();
    },
    count_items() {
      this.reset_counted_items();

      for (let product of this.products) {
        this.counted_subtotal += parseFloat(product.subtotal);
        this.counted_tax += parseFloat(product.tax_total);
        this.counted_total += parseFloat(product.total);
      }

      // rounding the results
      this.counted_subtotal = fixed_float(this.counted_subtotal);
      this.counted_tax = fixed_float(this.counted_tax);
      this.counted_total = fixed_float(this.counted_total);
    },
    get_taxes() {
      this.$http
        .get(`/inventory/taxes`)
        .then((response) => {
          this.taxes = response.data.results;
        })
        .catch((e) => {
          console.log(`Error while fetching taxes ! : ${e}`);
        });
    },
    set_discount_placeholder() {
      if (this.$refs.selected_discount_type.value === "f") {
        this.discount_type = "$";
      } else {
        this.discount_type = "%";
      }
      this.count_product_modal();
    },
    get_details() {
      this.$http
        .get(`/purchases/purchases_details/${this.$route.params.id}`)
        .then((response) => {
          // Destructure products and info from response.data.results and assign them to this.products and this.info
          ({ products: this.products, info: this.info } =
            response.data.results);

          // bind elements
          this.discount_type = this.info.discount_type;
          this.invoice_discount = this.info.discount;
          this.$refs.header_section.$refs.paid.checked = this.info.paid;
          this.$refs.header_section.$refs.inv_date.value = new Date(
            this.info.date
          )
            .toISOString()
            .split("T")[0];

          this.$refs.header_section.$refs.ext_id.value = this.info.external_id;
          let supplier = this.$refs.header_section.suppliers.filter(
            (supplier) => supplier.name === this.info.supplier
          );
          if (supplier.length > 0) {
            this.$refs.header_section.selected_supplier = supplier[0];
          }
        })
        .catch((e) => {
          console.log(
            `Error while fetching purchase report update view ${this.$route.params.report_id} : ${e}`
          );
        });
    },
  },
  mounted() {
    this.get_taxes();
    if (this.purchases_update || this.refund_update) {
      this.get_details();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/settings/purchases/main";
</style>
