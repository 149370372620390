<template>
  <div class="new-promo full-height row pb-5">
    <section_route
      :routes="[
        { name: $t('sidebar.management'), route: 'management' },
        {
          name: $t('sidebar.promos'),
          route: 'promos',
        },
        {
          name: get_title,
          route: '',
        },
      ]"
      class="pb-2"
    />

    <div class="inputs col-md-12">
      <div class="row">
        <p class="col-md-2 name">{{ $t("promo.promo_name") }}</p>
        <div class="col-md-7 static-floating-label-content">
          <input
            v-model="promo_name"
            class="form-control static-floating-input"
            type="text"
            placeholder=""
          />
          <label class="static-floating-label">{{
            $t("promo.promo_name")
          }}</label>
        </div>

        <div class="col row justify-content-end">
          <p class="col-md-4">{{ $t("common.active") }}</p>
          <p class="col-md-5">
            <!-- Rounded switch -->
            <label class="switch">
              <input v-model="is_active" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </p>
        </div>
      </div>
      <div class="row align-items-baseline mt-3">
        <p class="col-md-2 name">{{ $t("promo.start_date") }}</p>
        <input
          v-model="start_date"
          type="datetime-local"
          class="col-md-10 pos-input py-2"
        />
      </div>
      <div class="row align-items-baseline mt-3">
        <p class="col-md-2 name">{{ $t("promo.end_date") }}</p>
        <input
          v-model="end_date"
          type="datetime-local"
          class="col-md-10 pos-input py-2"
        />
      </div>
      <div class="row mt-3">
        <p class="col-md-2 name">{{ $t("common.days") }}</p>
        <div id="days" class="col-md-9 days">
          <label>
            <input
              ref="every_day_input"
              @click="set_days"
              type="checkbox"
              value="all"
            />
            {{ $t("common.everyday") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="0" />
            {{ $t("common.day_sat") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="1" />
            {{ $t("common.day_sun") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="2" />
            {{ $t("common.day_mon") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="3" />
            {{ $t("common.day_tue") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="4" />
            {{ $t("common.day_wed") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="5" />
            {{ $t("common.day_thu") }}</label
          >
          <label>
            <input @click="set_days" type="checkbox" value="6" />
            {{ $t("common.day_fri") }}</label
          >
        </div>
      </div>
    </div>

    <div class="products col-md-12">
      <div class="row" id="search-box">
        <!-- SEARCH COMPONENT STARTS -->
        <search_products
          ref="search_component"
          :search_css_class="'col-md-12 py-2 form-control'"
          :input_type="'search'"
          :allow_icon="null"
          :search_mixin="search_mixin"
        />
        <ul v-if="dropdown_toggle" class="card">
          <li
            v-for="promo_item in searched_products"
            :key="promo_item.product.id"
            @click="add_item(promo_item)"
            class="row justify-content-between"
          >
            <img
              :src="promo_item.product.image"
              alt=""
              width="32"
              height="32"
              class="col-md-3"
            />
            <p class="col-md-8" href="#">{{ promo_item.product.name }}</p>
          </li>
        </ul>
        <!-- END OF SEARCH COMPONENT -->

        <div class="col-md-12">
          <div class="products-wrapper mt-3">
            <div class="table-wrapper">
              <table class="primary-table w-100">
                <thead>
                  <tr>
                    <th>{{ $t("common.name") }}</th>
                    <th>{{ $t("common.barcode") }}</th>
                    <th>{{ $t("common.sale_price_before_tax") }}</th>
                    <th>{{ $t("common.tax_total") }}</th>
                    <th>{{ $t("common.sale_price") }}</th>
                    <th>{{ $t("common.tax_inc") }}</th>
                    <th>{{ $t("common.taxes") }}</th>
                    <th>{{ $t("common.cost") }}</th>
                    <th class="text-primary">{{ $t("common.discount") }}</th>
                    <th class="text-primary">
                      {{ $t("promo.quantity_condition") }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="promo_item in products"
                    :key="promo_item.product.id"
                  >
                    <td>{{ promo_item.product.name }}</td>

                    <td>{{ promo_item.product.barcode }}</td>

                    <td>{{ promo_item.product.subtotal }}</td>

                    <td>{{ promo_item.product.tax_total }}</td>
                    <td>{{ promo_item.product.price_with_tax }}</td>

                    <td>
                      <input
                        type="checkbox"
                        v-model="promo_item.product.tax_included"
                        disabled
                      />
                    </td>
                    <td>
                      <span v-for="tax in promo_item.product.tax" :key="tax.id">
                        {{ tax.name }}
                      </span>
                    </td>

                    <td>{{ promo_item.product.last_cost }}</td>

                    <td>
                      <div class="row">
                        <select
                          v-model="promo_item.discount_type"
                          class="form-select col-md-6"
                        >
                          <option value="f">{{ $t("common.fixed") }} $</option>
                          <option value="p">
                            {{ $t("common.percentage") }} %
                          </option>
                        </select>

                        <input
                          @change="
                            (e) => {
                              promo_item.discount = e.target.value;
                            }
                          "
                          :value="get_product_init_discount_value(promo_item)"
                          type="text"
                          placeholder=" Discount Amount"
                          class="form-control col-md-5 mt-3"
                        />
                      </div>
                    </td>

                    <td class="row">
                      <label class="col-md-6 text-start">
                        <input
                          type="checkbox"
                          v-model="promo_item.is_conditional"
                          :value="!promo_item.is_conditional"
                        />
                        {{ $t("common.enable") }}
                      </label>

                      <label
                        class="col-md-12"
                        :class="
                          promo_item.is_conditional ? '' : 'text-disabled'
                        "
                      >
                        <input
                          v-model="promo_item.is_all"
                          :checked="promo_item.is_all"
                          :value="true"
                          type="radio"
                          :name="'is_conditional' + promo_item.id"
                          :disabled="!promo_item.is_conditional"
                        />

                        {{ $t("promo.apply_to_all") }}
                        <input
                          @change="
                            (e) => {
                              promo_item.apply_to_all = e.target.value;
                            }
                          "
                          :value="promo_item.apply_to_all"
                          type="text"
                          class="form-control"
                          :disabled="!promo_item.is_conditional"
                        />
                      </label>

                      <label
                        class="col mt-1"
                        :class="
                          promo_item.is_conditional ? '' : 'text-disabled'
                        "
                      >
                        <input
                          v-model="promo_item.is_all"
                          :checked="!promo_item.is_all"
                          :value="false"
                          type="radio"
                          :name="'is_conditional' + promo_item.id"
                          :disabled="!promo_item.is_conditional"
                        />
                        {{ $t("promo.apply_to_next") }}
                        <input
                          @change="
                            (e) => {
                              promo_item.apply_to_next = e.target.value;
                            }
                          "
                          :value="promo_item.apply_to_next"
                          type="text"
                          class="form-control"
                          :disabled="!promo_item.is_conditional"
                        />
                      </label>
                    </td>

                    <td>
                      <button
                        @click="remove_item(promo_item.id)"
                        class="btn btn-outline-danger"
                      >
                        {{ $t("common.remove") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column justify-content-end pb-5 pb-sm-2">
      <div class="d-flex justify-content-end">
        <button
          v-if="is_create"
          @click="save"
          class="btn btn-outline-primary px-5"
        >
          {{ $t("common.save") }}
        </button>
        <button v-else @click="update" class="btn btn-outline-warning px-5">
          {{ $t("common.edit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import section_route from "@/components/section_route";
import search_products from "@/components/sell_screen/search_products";
import { to_local_datetime } from "@/helpers";

export default {
  name: "CreatePromo",
  components: {
    section_route,
    search_products,
  },
  filters: {
    localize_date: function (value) {
      const datetime = new Date(value);
      return `
      ${datetime.toLocaleDateString(
        "zh-Hans-CN"
      )}  | ${datetime.toLocaleTimeString()}
      `;
    },
  },
  computed: {
    is_create() {
      return this.$route.name === "new_promo";
    },
    get_title() {
      if (this.is_create) {
        return this.$t("promo.new_promo");
      }
      return `${this.$t("promo.edit_promo")} ${this.promo.name}`;
    },
  },
  data() {
    return {
      promo: {
        name: "",
      },
      promo_name: "",
      start_date: "",
      end_date: "",
      is_active: true,
      selected_days: "",

      search_mixin: null,
      searched_products: [],
      products: [],
      cached_products: [],
      dropdown_toggle: false,
    };
  },
  methods: {
    add_item(product_or_promo_item) {
      let is_product_exists;
      if (this.is_create) {
        is_product_exists = this.products.some(
          (obj) => obj.id === product_or_promo_item.id
        );
      } else {
        is_product_exists = this.products.some(
          (obj) => obj.product.id === product_or_promo_item.product.id
        );
      }

      if (!is_product_exists) {
        this.products.push(product_or_promo_item);
      }
    },
    remove_item(product_id) {
      const index = this.products.findIndex((p) => p.id === product_id);
      this.products.splice(index, 1);
    },
    set_days(e) {
      // in case if it's init_select_days
      const value = e.target ? e.target.value : e.value;
      const days_dom = document.querySelectorAll(
        "#days label input[type='checkbox']"
      );
      const days_array = Array.from(days_dom); // Convert NodeList to Array
      const all_days_dom = days_array.shift(); // contains EveryDays checkbox only

      if (this.selected_days.includes(value)) {
        this.unset_days(value);
      } else {
        if (value === "all") {
          this.select_all(all_days_dom, days_array);
        } else {
          this.selected_days += value;
        }
      }
    },
    set_selected_days() {
      // on update function
      const all = "0123456";
      const days_dom = document.querySelectorAll(
        'div#days input[type="checkbox"]'
      );

      if (this.promo.days_of_week.includes(all)) {
        // days_dom[0] is everyday checkbox
        days_dom[0].checked = true;
      }
      for (let day of days_dom) {
        if (this.promo.days_of_week.includes(day.value)) {
          day.checked = true;
        }
      }
      // bind selected_days
      this.selected_days = this.promo.days_of_week;
    },
    unset_days(value) {
      this.selected_days = this.selected_days.replace(value, "");
    },
    select_all(all_days_dom, days_array) {
      // check if disabled or enabled
      if (all_days_dom.checked) {
        this.selected_days = "0123456";
        days_array.forEach((day_dom) => {
          day_dom.disabled = !!all_days_dom.checked;
          day_dom.checked = true;
        });
      } else {
        this.selected_days = "";
        days_array.forEach((day_dom) => {
          day_dom.disabled = all_days_dom.checked;
          day_dom.checked = false;
        });
      }
    },
    save() {
      // products that be lightweight to send to the server
      let s_products = [];
      for (let item of this.products) {
        s_products.push({
          product: item.product.id,

          discount_type: item.product.discount_type,

          discount_in_percentage:
            item.discount_type === "p" ? item.discount : 0,

          discount_in_fixed_price:
            item.discount_type === "f" ? item.discount : 0,

          is_conditional: item.is_conditional,

          apply_to_all: item.apply_to_all,
          apply_to_next: item.apply_to_next,
          is_all: item.is_all,
        });
      }
      const form = {
        name: this.promo_name,
        start_date: this.start_date,
        end_date: this.end_date,
        is_active: this.is_active,
        days_of_week: this.selected_days,
        products: s_products,
      };

      this.$http
        .post("/promo/promo", form)
        .then(() => {
          this.$router.push({ name: "promos" });
        })
        .catch((e) => {
          let timer = 0;
          for (let [key, value] of Object.entries(e.response.data)) {
            setTimeout(() => {
              let sliced_value =
                value[0].length > 50
                  ? value[0].slice(0, 50) + "...."
                  : value[0];

              this.$store.dispatch("utils/push_alert", {
                message: `${key.split("_").join(" ")} : ${sliced_value}`,
                type: "danger",
              });
            }, timer);
            timer += 800;
          }
          console.log("Error while trying add new Promo", e);
        });
    },
    update_request(form) {
      this.$http
        .put("/promo/promo", { id: this.$route.params.id, ...form })
        .then(() => {
          this.$router.push({ name: "promos" });
          this.$store.dispatch("utils/push_alert", {
            message: `Promo Updated Successfully`,
            type: "success",
          });
        })
        .catch((e) => {
          let timer = 0;
          for (let [key, value] of Object.entries(e.response.data)) {
            setTimeout(() => {
              let sliced_value =
                value[0].length > 50
                  ? value[0].slice(0, 50) + "...."
                  : value[0];

              this.$store.dispatch("utils/push_alert", {
                message: `${key.split("_").join(" ")} : ${sliced_value}`,
                type: "danger",
              });
            }, timer);
            timer += 800;
          }
          console.log("Error while trying to update Promo", e);
        });
    },
    update() {
      // products that be lightweight to send to the server
      let promo_items = [];
      for (let item of this.products) {
        promo_items.push({
          promo: this.$route.params.id,
          id: item.id || null,
          product: item.product.id,

          discount_type: item.discount_type,

          discount_in_percentage:
            item.discount_type === "p" ? item.discount : 0,

          discount_in_fixed_price:
            item.discount_type === "f" ? item.discount : 0,

          is_conditional: item.is_conditional,

          apply_to_all: item.apply_to_all,
          apply_to_next: item.apply_to_next,
          is_all: item.is_all,
        });
      }

      const form = {
        name: this.promo_name,
        start_date: this.start_date,
        end_date: this.end_date,
        is_active: this.is_active,
        days_of_week: this.selected_days,
        promoitem_set: promo_items,
      };

      this.update_request(form);
    },
    get_promo() {
      this.$http
        .get(`/promo/promo_details/${this.$route.params.id}`)
        .then((response) => {
          this.promo = response.data[0];

          // in this case document.title would be `OnePos | Update Promo`
          document.title = `${document.title} ${this.promo.name}`;

          // binding promo object to frontend
          this.promo_name = this.promo.name;
          this.is_active = this.promo.is_active;
          this.start_date = to_local_datetime(this.promo.start_date);
          this.end_date = to_local_datetime(this.promo.end_date);
          this.set_selected_days(this.promo.days_of_week);
          for (let promo_item of this.promo.promoitem_set) {
            // make it similar to search_mixin object
            const item = {
              ...promo_item,
              discount_type: promo_item.discount_in_percentage > 0 ? "p" : "f",

              discount:
                promo_item.discount_in_percentage > 0
                  ? promo_item.discount_in_percentage
                  : promo_item.discount_in_fixed_price,

              discount_in_fixed_price: promo_item.discount_in_fixed_price,
              discount_in_percentage: promo_item.discount_in_percentage,
            };
            this.add_item(item);
            this.init_select_days();
          }
        })
        .catch((e) => {
          console.log("error while getting a promo details : ", e);
        });
    },
    get_product_init_discount_value(product) {
      if (product.discount && product.discount > 0) {
        return product.discount;
      } else if (
        product.discount_in_fixed_price &&
        product.discount_in_fixed_price > 0
      ) {
        return product.discount_in_fixed_price;
      } else if (
        product.discount_in_percentage &&
        product.discount_in_percentage > 0
      ) {
        return product.discount_in_percentage;
      }
      return 0;
    },
    // mounted functions
    dropdown_listeners() {
      let self = this;
      this.$refs.search_component.$refs.search_input.addEventListener(
        "focus",
        function () {
          self.dropdown_toggle = true;
        }
      );
      this.$refs.search_component.$refs.search_input.addEventListener(
        "blur",
        function () {
          // so user can select the dropdown item increase delay
          // if modal not showing or set dropdown_toggle based
          // if there is showing modal
          setTimeout(() => {
            self.dropdown_toggle = false;
          }, 200);
        }
      );
    },
    set_search_mixins() {
      // set search_mixin data
      this.search_mixin = (product) => {
        return {
          product: { ...product },

          discount_type: "f",
          discount: 0,
          discount_in_fixed_price: 0,
          discount_in_percentage: 0,
          is_conditional: false,
          is_all: false,
          apply_to_all: 0,
          apply_to_next: 0,
        };
      };
    },
    init_select_days() {
      if (this.selected_days === "0123456") {
        this.set_days(this.$refs.every_day_input);
      }
    },
  },
  mounted() {
    this.dropdown_listeners();
    this.set_search_mixins();
    if (!this.is_create) {
      this.get_promo();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/settings/promo/main";
</style>
