import { OrderDiscount } from "@/js/sell_screen/discount/discount";

const state = {
  current_order: JSON.parse(localStorage.getItem("current_order")) || null,
  // saved_sales
  saved_orders: JSON.parse(localStorage.getItem("saved_orders")) || [],
};

const actions = {
  save_current_order({ commit }, order) {
    commit("save_current_order", order);
  },
  save_order({ commit }, order) {
    commit("save_order", order);
  },
  delete_current_order({ commit }) {
    commit("delete_current_order");
  },
  delete_selected_saved_sale({ commit }, index) {
    commit("delete_selected_saved_sale", index);
  },
};
const mutations = {
  delete_current_order: () => {
    localStorage.removeItem("current_order");
    state.current_order = new OrderDiscount(null, new Date());
  },
  save_current_order: (state, order) => {
    localStorage.removeItem("current_order");
    localStorage.setItem("current_order", JSON.stringify(order));
    state.current_order = order;
  },
  save_order: (state, order) => {
    localStorage.setItem(
      "saved_orders",
      JSON.stringify([...state.saved_orders, order])
    );
    state.saved_orders.push(order);
  },
  delete_selected_saved_sale: (state, index) => {
    state.saved_orders.splice(index, 1);
    localStorage.setItem("saved_orders", JSON.stringify(state.saved_orders));
  },
};
const getters = {
  get_current_saved_order: (state) => {
    return state.current_order;
  },
  get_saved_orders: (state) => {
    let orders = [];
    for (let order of state.saved_orders) {
      const _order = new OrderDiscount(
        order.client,
        order.datetime,
        order.items,
        order.cart_fixed_discount,
        order.cart_discount_percentage,
        order.total_discount
      );
      orders.push(_order);
    }
    return orders;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
