<template>
  <input
    ref="search_input"
    v-model="search_val"
    @input="get_products_request"
    @keyup.enter="get_products_request"
    type="search"
    class="pos-input"
    :placeholder="$t('common.search_by_product_name_or_barcode')"
  />
</template>

<script>
import { search_products } from "@/js/services/api_services";
import { display_error_messages } from "@/js/utils";

export default {
  name: "search_products",
  data() {
    return {
      search_val: "",
      products: [],
    };
  },
  methods: {
    reset_products() {
      if (this.search_val.length > 0) {
        this.$parent.sf = true;
      } else {
        this.$parent.sf = false;
        this.$parent.searched_products = [];
      }
    },
    get_products_request() {
      this.reset_products();
      search_products(this.search_val).then(({ data, errors }) => {
        if (data && data.length > 0) {
          if (data.length === 1) {
            this.search_val = "";
            const item = data[0];
            this.$parent.products = [item];
          } else if (data.length > 1) {
            data.forEach((item) => (item.ordered_quantity = 1));
            this.$parent.products = data;
          }
        }
        if (errors) {
          display_error_messages(errors);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  position: absolute;
  top: 0.28rem;
  left: 0.28rem;
  width: 32px;
  height: 32px;
}
.search-bar {
  font-family: "Manrope", serif;
  font-style: normal;
  font-weight: 500;
  //font-size: 10px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.6);
  background: #f8f9fc;
  border: 1px solid #e0e9f4;
  border-radius: 8px;
  padding-left: 2.2rem;
}
svg {
  padding-top: 10px;
  margin-top: 10px;
}
</style>
